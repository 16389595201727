import * as React from 'react';
import { Box, Container, Typography, useMediaQuery } from '@material-ui/core';
import { parse } from '../../../_hooks/useParser';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as ScanditSDK from 'scandit-sdk';
import { BarcodePicker, Barcode, ScanSettings } from 'scandit-sdk';
import history from '../../../../core-utils/history';
import './index.css';
import CancelIcon from '../../../../../public/assets/cancel.svg';
import { properties } from '../../../../core-utils/properties';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import theme from '../../../../core-utils/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      padding: 0,
      position: 'relative',
    },
    videoContainer: {
      color: theme.palette.common.white,
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: '100%',
    },
    color: {
      background: 'rgba(57, 57, 56, 0.74)',
      flex: 1
    },
    scanner: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: -2,
    },
    title: {
      color: theme.palette.common.white,
      fontWeight: 900,
      textAlign: 'center',
      width: '100%',
      top: '10%',
      padding: '0 28px',
      position: 'absolute',
      zIndex: 3,
    },
    close: {
      position: 'absolute',
      bottom: '10%',
      width: '100%',
      zIndex: 3,
    },
    overlayBox: {
      width: '84%',
      paddingBottom: '26%',
      position: 'relative',
    },
    '@global': {
      '.overlayBox:after': {
        position: 'absolute',
        width: '30px',
        height: '30px',
        borderColor: '#007aff',
        borderStyle: 'solid',
        content: '""',
        top: 0,
        right: 0,
        borderWidth: '2px 2px 0 0',
      },
      '.overlayBox:before': {
        position: 'absolute',
        width: '30px',
        height: '30px',
        borderColor: '#007aff',
        borderStyle: 'solid',
        content: '""',
        top: 0,
        left: 0,
        borderWidth: '2px 0 0 2px',
      },
      '.child:after': {
        position: 'absolute',
        width: '30px',
        height: '30px',
        borderColor: '#007aff',
        borderStyle: 'solid',
        content: '""',
        bottom: 0,
        left: 0,
        borderWidth: '0 0 2px 2px',
      },
      '.child:before': {
        position: 'absolute',
        width: '30px',
        height: '30px',
        borderColor: '#007aff',
        borderStyle: 'solid',
        content: '""',
        bottom: 0,
        right: 0,
        borderWidth: '0 2px 2px 0',
      },
    },
  }),
);

const ScannerPage = () => {
  const [bcodePicker, setPicker] = React.useState<BarcodePicker>();

  const handle = async () => {
    const license = properties.SCANDIT_LICENSE_KEY.replace(
      /^\n+|\n+$/g,
      '',
    ).replace(/^\"+|\"+$/g, '');
    await ScanditSDK.configure(license, {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
    })
      .then(() => {
        const ele = document.getElementById('scandit-barcode-picker');

        if (ele !== null) {
          BarcodePicker.create(ele, {
            vibrateOnScan: true,
            guiStyle: BarcodePicker.GuiStyle.NONE,
            videoFit: BarcodePicker.ObjectFit.COVER,
            scanSettings: new ScanSettings({
              enabledSymbologies: [Barcode.Symbology.PDF417],
              searchArea: { x: 0.1, y: 0.36, height: 0.29, width: 0.8 },
            }),
          })
            .then((barcodePicker) => {
              setPicker(barcodePicker);

              barcodePicker.on('scan', (scanResult) => {
                const d = scanResult.barcodes.reduce((string, barcode) => {
                  return (
                    string +
                    `${Barcode.Symbology.toHumanizedName(barcode.symbology)}: ${
                      barcode.data
                    }\n`
                  );
                }, '');
                const data = parse(d);
                barcodePicker.destroy();
                history.push('/add-profile', data);
              });
            })
            .catch((e) => {});
        }
      })
      .catch((e) => {});
  };

  const handleReset = () => {
    //@ts-ignore
    bcodePicker !== undefined && bcodePicker.destroy();
  };
  const classes = useStyles();
  React.useEffect(() => {
    handle();
    return handleReset;
  }, []);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box height="100%" width="100%" display="flex" flexDirection="column">
        <Box id="video-container" className={classes.videoContainer}>
          <Box id="scandit-barcode-picker" className={classes.scanner}></Box>
          <Box
            position="absolute"
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
          >
            <Box id="color" className={classes.color} />
            <Box display="flex">
              <Box id="color" className={classes.color} />
              <Box
                className={classNames(classes.overlayBox, 'overlayBox')}
              >
                <span className={'child'}></span>
              </Box>
              <Box id="color" className={classes.color} />
            </Box>
            <Box id="color" className={classes.color} />
          </Box>
          <Typography variant="subtitle2" className={classes.title}>
            <FormattedMessage id="scanBarcode" />
          </Typography>
          <Box display="flex" justifyContent="center" className={classes.close}>
            <img
              onClick={() => {
                handleReset();
                history.goBack();
              }}
              src={CancelIcon}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ScannerPage;

import { createMuiTheme } from '@material-ui/core/styles';
import './Avenir-font/font.css';

export const pxToRem = (fontSize) => {
  return `${fontSize / 16}rem`;
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#007aff',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1db985',
      dark: '#91DDC3',
    },
    warning: {
      main: '#f07844',
    },
    success: {
      main: '#13ce66',
      light: '#90f9c8',
    },
    common: {
      black: '#212121', //fix
      white: '#ffffff',
    },
    error: {
      main: '#ff4949',
      light: '#ff7c7c',
    },
    background: {
      default: '#faffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#02010e',
      secondary: '#91909a',
      hint: '#979da9',
    },
    grey: {
      100: '#ecebf3',
      200: '#c1c0c8',
      300: '#91909a',
      400: '#474657',
      500: '#02010e',
      600: '#7c98b6',
      700: '#516f90',
      800: '#425b76',
      900: '#2d3e50',
      A100: '#2d3e50b3', // transparent grey overlay
    },
    action: {
      hover: '#eeeeee',
      hoverOpacity: 0.2,
    },
    // status: {
    //      info: '#ff902a'
    //   },
  },
  spacing: 4,
  shadows: [
    'none',
    '0 7px 24px 0 rgba(48, 49, 51, 0.08)', // paper
    '0 1px 3px 0 rgba(48, 49, 51, 0.1)', // button
    '0 1px 3px 0 rgba(0, 0, 0, 0.04)', // paper
    '0 1px 2px 0 rgba(0, 0, 0, 0.08)', // outlined button
    '1px 0 0 0 #f1f1f3',
    '0 1px 0 0 #eaedf3', //menu item
    '0 2px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.02)', //card
    '0 1px 1px 0 rgba(19,31,21,0.1)',
    '0 0 11px rgb(158,158,158)', //card - hover
    '0 4px 8px 0 rgba(48,49,51,0.1)', //resource-card hover
    '0 7px 6px 0 rgba(48, 49, 51, 0.08)', // paper
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  transitions: {
    duration: {
      enteringScreen: 700,
      leavingScreen: 500,
    },
  },
  typography: {
    fontFamily: 'Avenir',
    h1: {
      fontSize: pxToRem(28),
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '38px',
      letterSpacing: 'normal',
    },
    h2: {
      fontSize: pxToRem(18),
      fontWeight: 900,
      fontStyle: 'normal',
      lineHeight: '30px',
      letterSpacing: 'normal',
    },
    h3: {
      fontSize: pxToRem(18),
      fontWeight: 'normal',
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '30px',
    },
    h4: {
      //TODO
      fontSize: pxToRem(23),
      fontWeight: 600,
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '37px',
    },
    h5: {
      // todo
      fontSize: pxToRem(20),
      fontWeight: 'bold',
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
    },
    h6: {
      // todo
      fontSize: pxToRem(18),
      fontWeight: 'bold',
      letterSpacing: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.56',
    },
    subtitle1: {
      fontSize: pxToRem(16),
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      //TODO
      fontSize: pxToRem(16),
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    body1: {
      fontSize: pxToRem(14),
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    body2: {
      fontSize: pxToRem(14),
      fontWeight: 300,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    caption: {
      fontSize: pxToRem(12),
      fontWeight: 900,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 'normal',
    },
    overline: {
      fontSize: pxToRem(12),
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: 'normal',
      textTransform: 'inherit',
    },
    button: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.57',
      letterSpacing: 'normal',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: '50px',
        borderRadius: '25px',
        textTransform: 'none',
        fontFamily: 'Avenir',
        fontSize: '18px',
        fontWeight: 900,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 'normal',
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#faffff',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
  },
});

export default theme;

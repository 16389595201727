import React from 'react';

interface IPerson {
  color?: string;
  size?: string;
}

const Person: React.FC<IPerson> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={color || '#007AFF'}
              d="M13.333 17.333c5.155 0 9.334 4.179 9.334 9.334 0 .736-.597 1.333-1.334 1.333-.736 0-1.333-.597-1.333-1.333C20 22.985 17.015 20 13.333 20s-6.666 2.985-6.666 6.667c0 .736-.597 1.333-1.334 1.333C4.597 28 4 27.403 4 26.667c0-5.155 4.179-9.334 9.333-9.334zm0-13.333c2.946 0 5.334 2.388 5.334 5.333 0 2.946-2.388 5.334-5.334 5.334C10.388 14.667 8 12.279 8 9.333 8 6.388 10.388 4 13.333 4zM27 5.787c.486-.552 1.327-.606 1.88-.12.548.49.595 1.33.107 1.88l-3.48 4c-.25.284-.609.449-.987.453-.38.003-.745-.157-1-.44l-1.853-2.08c-.49-.552-.44-1.397.113-1.887.552-.49 1.397-.439 1.887.114l.84.946zm-13.667.88c-1.472 0-2.666 1.194-2.666 2.666 0 1.473 1.194 2.667 2.666 2.667C14.806 12 16 10.806 16 9.333c0-1.472-1.194-2.666-2.667-2.666z"
              transform="translate(-28 -104) translate(28 104)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Person;

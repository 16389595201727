import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ErrorDetails from '../../../_components/atoms/ErrorDetails';
import ErrorInfo from '../../../_components/atoms/ErrorInfo';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

export interface ErrorCardProps {
  width?: number | string;
  height?: number | string;
  errorIcon: any;
  errorDetails?: string;
  errorInfo?: string;
}

const ErrorCard: React.FC<ErrorCardProps> = ({
  width,
  height,
  errorIcon,
  errorInfo,
  errorDetails,
}) => {
  return (
    <Grid
      container
      style={{
        width: width || 'inherit',
        height: height || 'inherit',
      }}
      direction="column"
      justify="center"
      spacing={2}
    >
      <ErrorInfo errorIcon={errorIcon} error={errorInfo}></ErrorInfo>
      <ErrorDetails error={errorDetails}></ErrorDetails>
    </Grid>
  );
};

export default ErrorCard;

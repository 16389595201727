import React from 'react';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import RadioButtonList from '../../molecules/RadioButtonList';
import { downloadOptions } from '../../../_utils/constants';
import { getDownloadFormat } from './hook';
import LoadingState from '../../../../common/_components/atoms/LoadingState';
import ErrorCard from '../../molecules/ErrorCard';
import errorIcon from '../../../../../public/assets/errorStateIcon.svg';

interface DownloadData {}

const DownloadData: React.FC<DownloadData> = () => {
  const { formatMessage } = useIntl();
  const state = getDownloadFormat();

  if (state.loading) return <LoadingState />;
  else
    return state.error ? (
      <ErrorCard errorIcon={errorIcon} height={400} />
    ) : (
      <React.Fragment>
        {
          <DefaultSubTemplate
            header={
              <Typography variant="h1">
                <FormattedMessage id="downloadText" />
              </Typography>
            }
            subHeader={
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id="downloadDescription" />
              </Typography>
            }
            footerHeight={48}
            body={
              <Box width="100%" mb={18.5}>
                <RadioButtonList
                  inputs={downloadOptions.inputs.map((input) => ({
                    ...input,
                    content: formatMessage({ id: input.labelKey }),
                    subLabel: undefined,
                  }))}
                  onChange={state.handleChange}
                />
              </Box>
            }
            actionHandler={
              state.selected === 'CSV' ? state.downloadCSV : state.downloadExcel
            }
            actionText={<FormattedMessage id="downloadActionText" />}
            actionProps={{
              disabled: state.disableDownloadButton,
            }}
          />
        }
      </React.Fragment>
    );
};

export default DownloadData;

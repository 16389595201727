import React from 'react';
import imageCompression from 'browser-image-compression';

const useCamera = () => {
  const options = {
    maxSizeMB: 1,
  };

  const getFileFromImageUrl = (url: string): Promise<File> => {
    return new Promise((resolve, reject) => {
      imageCompression
        .getFilefromDataUrl(url, 'newtest.jpg')
        .then((image) => {
          resolve(image);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const getUrlFromFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      imageCompression
        .getDataUrlFromFile(file)
        .then((url) => {
          resolve(url);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const getCompressedImage = (
    image: string | File,
  ): Promise<{ file: File; url: string }> => {
    return new Promise(async (resolve, reject) => {
      let file = image;
      try {
        if (!(image instanceof File)) {
          file = await getFileFromImageUrl(image);
        }
        const newFile = await imageCompression(file as File, options);
        const newUrl = await getUrlFromFile(newFile);
        resolve({
          file: newFile,
          url: newUrl,
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    getFileFromImageUrl,
    getUrlFromFile,
    getCompressedImage
  };
};

export default useCamera;

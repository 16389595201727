import React from 'react';
import { BACK_EVENT } from '../../_utils/events';

const useBack = (callback) => {
  BACK_EVENT;

  React.useEffect(() => {
    window.addEventListener(BACK_EVENT, callback);

    return () => {
      window.removeEventListener(BACK_EVENT, callback);
    };
  }, [callback]);

  return;
};

export default useBack;

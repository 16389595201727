import { Box, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      position: 'relative',
      borderRadius: theme.spacing(1),
    },
    close: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    image: {
      maxHeight: '320px',
      maxWidth: '100%',
    },
  }),
);

interface IPreviewImage {
  src: string;
  onClose: any;
  name?: string | null;
}

const PreviewImage: React.FC<IPreviewImage> = ({ name, src, onClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <IconButton onClick={onClose} className={classes.close}>
        <Close />
      </IconButton>
      <img src={src} alt={name || ''} className={classes.image} />
    </Box>
  );
};

export default PreviewImage;

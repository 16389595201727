import { Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import React from 'react';
import Card, { ICard } from '../../../../common/_components/atoms/Card';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4.5),
    },
    name: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
    },
    navlink: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      width: 'fit-content',
    },
  }),
);

interface IPatientCard extends ICard {
  name: string;
  onViewTimeLine?: any;
}

const PatientCard: React.FC<IPatientCard> = ({
  name,
  onViewTimeLine,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root} {...rest}>
      <Typography variant="subtitle1" className={classes.name}>
        {name}
      </Typography>
      <Link
        variant="body2"
        onClick={onViewTimeLine}
        className={classes.navlink}
      >
        <FormattedMessage id= "viewTestTimeline"/>
        <ArrowForward
          style={{ marginLeft: theme.spacing(1), fontSize: '12px' }}
        />
      </Link>
    </Card>
  );
};

export default PatientCard;

import React from 'react';
import { useHistory } from 'react-router-dom';
import history from '../../../../core-utils/history';
import { getTests } from '../../../../services';
import useBack from '../../../_hooks/useBack';

interface RESULT {
  date: string;
  value: string;
}

interface RESULTITEM {
  result: RESULT;
  image?: string;
}

export const useResults = () => {
  const [results, setResults] = React.useState([] as RESULTITEM[]);
  const [name, setName] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>('');
  const { location } = useHistory<any>();
  const patientId = location.state && location.state.patientId;
  const tempName = location.state && location.state.name;

  useBack(history.goBack);

  React.useEffect(() => {
    if (patientId && tempName) {
      setName(tempName);

      getTests(patientId)
        .then((res) => {
          setResults(
            res.data.rows.sort((a, b) => {
              return (
                new Date(b.result.date).valueOf() -
                new Date(a.result.date).valueOf()
              );
            }),
          );
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      history.push('/profiles');
    }
  }, []);

  return {
    name,
    loading,
    error,
    tests: results,
  };
};

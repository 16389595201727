import React from 'react';

export default (({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '30'}
      height={size || '30'}
      viewBox="0 0 30 30"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill={color || '#007AFF'}
                d="M15 2.5C8.096 2.5 2.5 8.096 2.5 15S8.096 27.5 15 27.5 27.5 21.904 27.5 15c0-3.315-1.317-6.495-3.661-8.839C21.495 3.817 18.315 2.5 15 2.5zm5.375 9.512l-5.713 7.5c-.235.307-.6.487-.987.488-.385.002-.749-.173-.988-.475l-3.05-3.887c-.274-.353-.34-.826-.172-1.24.169-.414.545-.707.988-.769.443-.061.885.118 1.16.471l2.037 2.6 4.725-6.25c.272-.357.715-.542 1.16-.485.446.058.827.348 1 .763.174.414.112.89-.16 1.247v.037z"
                transform="translate(-299 -273) translate(28 253) translate(271 20)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}) as React.FC<React.SVGProps<SVGSVGElement> & { size?: string }>;

import React from 'react';
import { Select as MuiSelect, SelectProps } from '@material-ui/core';

export interface ISelect extends SelectProps {}

const Select: React.FC<ISelect> = ({ ...rest }) => {
  return (
    <MuiSelect
      {...rest}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        elevation: 1,
        getContentAnchorEl: null,
      }}
    />
  );
};

export default Select;

import React from 'react';
import ResultSubmitted from '../../_components/molecules/ResultSubmitted';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';

export interface IThankYou {}

const ThankYou: React.FC<IThankYou> = () => {
  return <DefaultTemplate body={<ResultSubmitted />} showBackButton={false} />;
};

export default ThankYou;

import * as React from 'react';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Card, CircularProgress } from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import Switcher from '../../molecules/Switch/index';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useContactTracing } from './hook';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import history from '../../../../core-utils/history';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '56px',
      marginLeft: -theme.spacing(7),
      marginRight: -theme.spacing(7),
      cursor: 'pointer',
      boxShadow: theme.shadows[11],
      borderTop: '1px solid #dfe3eb',
    },
    image: {
      padding: theme.spacing(0, 2, 0, 6),
    },
    text: {
      marginTop: theme.spacing(4),
    },
    avatarRoot: {
      marginRight: '-4px',
      zIndex: 1,
    },
    popup: {
      textAlign: 'center',
    },
    close: {},
    dialog: {
      padding: '0px 0px 0px 20px',
    },
    paperWidthSm: {
      maxHeight: '370px',
      width: '100%',
    },
    paper: {
      overflow: 'auto',
    },
    gutters: {
      paddingLeft: '20px',
    },
    root: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    content: {
      padding: '0px 0px 30px 0px',
    },
    button: {
      '&:hover': {
        backgroundColor: '#f5f8fa',
      },
    },
    retryButton: {
      width: '159px',
    },
  }),
);

interface IContactTracing {}

const ContactTracing = () => {
  const classes = useStyles();

  const {
    toggleOn,
    selected,
    handleConsent,
    toggleLoad,
    updateError,
  } = useContactTracing();

  return (
    <>
      <DefaultSubTemplate
        header={
          <Box>
            <Typography variant="h1">
              <FormattedMessage id="privacyText" />
            </Typography>
          </Box>
        }
        body={
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginTop={theme.spacing(2.5)}
            >
              <Card className={classes.card}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="100%"
                  borderBottom="1px solid #dfe3eb"
                  padding="28px"
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    <FormattedMessage id="optionText" />
                  </Typography>
                  <Switcher
                    checkedState={toggleLoad ? undefined : toggleOn}
                    onToggleChange={handleConsent}
                    disabled={toggleLoad}
                  />
                </Box>
              </Card>
              {toggleOn ? (
                <Typography
                  className={classes.text}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  <FormattedMessage id="optionDescToggleOn" />
                </Typography>
              ) : (
                <Typography
                  className={classes.text}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  <FormattedMessage id="optionDescToggleOff" />
                </Typography>
              )}

              <Box className={classes.text} display="flex" height="20px">
                {toggleLoad && (
                  <>
                    <Typography variant="overline">
                      Saving your settings...
                    </Typography>
                    <CircularProgress
                      color="primary"
                      style={{
                        height: '20px',
                        width: '20px',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
                {updateError && (
                  <Typography
                    variant="overline"
                    style={{ color: theme.palette.error.main }}
                  >
                    Oh Snap! Something went wrong. Please check your internet
                    connectivity.
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginTop={theme.spacing(1.5)}
            >
              <Card
                className={classes.card}
                onClick={() => history.push('/storage', { patient: selected })}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="100%"
                  borderBottom="1px solid #dfe3eb"
                  padding="28px"
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    <FormattedMessage id="storedData" />
                  </Typography>
                  <NavigateNextOutlinedIcon
                    style={{ fill: theme.palette.grey[300] }}
                  />
                </Box>
              </Card>
              <Typography
                className={classes.text}
                variant="subtitle1"
                color="textSecondary"
              >
                <FormattedMessage id="storedDataDescription" />
              </Typography>
            </Box>
          </>
        }
      />
    </>
  );
};

export default ContactTracing;

interface IAddProfileAndTestRequest {
  patient: any;
  test: any;
}

export function buildAddProfileAndTest(
  object: any,
  deviceIdentifier: string,
): IAddProfileAndTestRequest {
  const addProfileRequestBody: IAddProfileAndTestRequest = {
    patient: {},
    test: {},
  };

  addProfileRequestBody.patient = {
    ...getPersonalDetailsFromForm(object[0].inputs),
    ...getPersonalDetailsFromForm(object[1].inputs),
  };

  addProfileRequestBody.patient.address = {
    ...getPersonalDetailsFromForm(object[0].inputs),
  };

  delete addProfileRequestBody.patient.streetAddress;
  delete addProfileRequestBody.patient.city;
  delete addProfileRequestBody.patient.state;
  delete addProfileRequestBody.patient.zipcode;
  delete addProfileRequestBody.patient.address.dateOfBirth;
  delete addProfileRequestBody.patient.address.name;

  addProfileRequestBody.patient.dateOfBirth = formatDate(
    addProfileRequestBody.patient.dateOfBirth,
  );

  const conditons = {
    ...getPatientConditionDetailsAsValueFromForm(object[2].inputs),
  };
  if (object[5]) {
    addProfileRequestBody.patient.consent = {
      given: object[5].value == 'TRUE',
      timestamp: new Date().toUTCString(),
    };
  }
  addProfileRequestBody.patient.employedInHealthcare =
    conditons.employedInHealthcare;
  addProfileRequestBody.patient.residenceInCongreateCare =
    conditons.residenceInCongreateCare;
  addProfileRequestBody.test.pregnant = conditons.pregnant;
  addProfileRequestBody.test.firstTest = conditons.firstTest;
  addProfileRequestBody.test.symptomatic = conditons.symptomatic;
  addProfileRequestBody.test.result = {
    value: object[4].value,
    date: new Date().toUTCString(),
  };

  addProfileRequestBody.test.deviceIdentifier = deviceIdentifier;
  return addProfileRequestBody;
}

function getPersonalDetailsFromForm(req: any) {
  const result = {};
  req.forEach((fieldInput) => {
    if (fieldInput.value)
      result[replaceHiphenToUnderscore(fieldInput.id)] = fieldInput.value;
  });
  return result;
}

function replaceHiphenToUnderscore(str: string): string {
  return str.split('-').join('_');
}

function formatDate(date) {
  if (new Date(date).toString().includes('Invalid')) {
    return date;
  }
  const d = date.split('-');
  let month = d[1];
  let day = d[2];
  const year = d[0];

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
}

function getPatientConditionDetailsAsValueFromForm(req: any) {
  const result: any = {};
  req.forEach((fieldInput) => {
    switch (fieldInput.id) {
      case 'symptomatic': {
        result.symptomatic = {};
        if (fieldInput.checked) {
          result.symptomatic.value = 'YES';
        } else {
          result.symptomatic.value = 'NO';
        }
        break;
      }
      case 'pregnant': {
        if (fieldInput.checked) {
          result.pregnant = 'PREGNANT';
        } else {
          result.pregnant = 'NOT_PREGNANT';
        }
        break;
      }
      case 'employedInHealthcare': {
        result.employedInHealthcare = {};
        if (fieldInput.checked) {
          result.employedInHealthcare.value = 'YES';
        } else {
          result.employedInHealthcare.value = 'NO';
        }
        break;
      }
      case 'residenceInCongreateCare': {
        result.residenceInCongreateCare = {};
        if (fieldInput.checked) {
          result.residenceInCongreateCare.value = 'YES';
        } else {
          result.residenceInCongreateCare.value = 'NO';
        }
        break;
      }
      case 'firstTest': {
        result.firstTest = {};
        if (fieldInput.checked) {
          result.firstTest.value = 'YES';
        } else {
          result.firstTest.value = 'NO';
        }
        break;
      }
    }
  });

  return result;
}

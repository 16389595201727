const addPatientScreenMessages = {
    fieldIsRequiredError: 'Field is required',
    invalidInputError: 'Field is invalid',
    addFamilyLabel : 'Add member',
    stepLabel : 'Step',
    readResultHeader :'Learn how to read the results',
    back : 'Back',
    finish : 'Finish',
    continue : 'Continue',
    imageUploadTitle: 'Let’s take a picture of your device'
};

export default addPatientScreenMessages;
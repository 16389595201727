import { Grid } from '@material-ui/core';
import React from 'react';
import CheckboxCard, {
  ICheckboxCard,
} from '../../../../common/_components/atoms/CheckboxCard';

export interface ICheckboxCardList {
  cards: ICheckboxCard[];
  onChange?: ICheckboxCard['onChange'];
}

const CheckboxCardList: React.FC<ICheckboxCardList> = ({
  cards,
  onChange,
  ...rest
}) => {
  return (
    <Grid container direction="row" spacing={3}>
      {cards.map((card) => (
        <Grid item xs={12} key={card.id}>
          <CheckboxCard onChange={onChange} {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxCardList;

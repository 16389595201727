export const properties = {
  BEFFE_URL: process.env.BEFFE_URL || '',
  studioUrl: window.location.origin,
  SCANDIT_LICENSE_KEY: process.env.SCANDIT_LICENSE_KEY || '',
  API_KEY: process.env.API_KEY || '',
  AUTH_DOMAIN: process.env.AUTH_DOMAIN || '',
  PROJECT_ID: process.env.PROJECT_ID || '',
  STORAGE_BUCKET: process.env.STORAGE_BUCKET || '',
  MESSAGING_SENDER_ID: process.env.MESSAGING_SENDER_ID || '',
  APP_ID: process.env.APP_ID || '',
  MEASUREMENT_ID: process.env.MEASUREMENT_ID || '',
  LD_CLIENT_ID: process.env.LD_CLIENT_ID || ''
};

export default properties;

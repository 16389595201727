const globalMessages = {
  continue: 'Continue',
  defaultErrorMsg: 'Oh Snap! Something went wrong.',
  defaultErrorDetailMsg:
    'Please check your internet connection or try again later.',
  retry: 'Retry',
  loadingMessage: 'Warming up the processor....',
  checkInternetErrorMsg:
    'Oh Snap! Something went wrong. Please try checking both wifi/4G or try again later.',
  noimage: 'Image upload failed, Please try again',
  fileSizeLarge: 'File is too large, select a file less than 10MB',
  uploadOnlyImage:
    'File extension is not supported, upload only jpeg or png files',
};

export default globalMessages;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import { terms, privacy } from './content';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { TERMS, HOME } from '../../../../core-utils/routes';
import { formatDate } from '../../../../core-utils/helpers';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import useBack from '../../../_hooks/useBack';
import history from '../../../../core-utils/history';
import useQueryParam from '../../../../core-utils/hooks/useQueryParam';

const useStyles = makeStyles((theme) => ({
  body: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '& strong': {
      ...theme.typography.body1,
    },
    '& h2': {
      ...theme.typography.h2,
    },
    ...theme.typography.body2,
  },
  markdown: {
    marginBlockStart: '-1em',
    marginBlockEnd: '-1em',
  },
}));

const TermsAndPrivacyContent = (props) => {
  const classes = useStyles();
  const pageContent = location.pathname == TERMS ? terms : privacy;
  const { param: deviceID } = useQueryParam('deviceID');

  const { title, body, updatedAt } = pageContent;

  useBack(() => {
    history.push(`${HOME}${deviceID ? `?deviceID=${deviceID}` : ''}`);
  });

  return (
    <DefaultSubTemplate
      header={<Typography variant="h1">{title}</Typography>}
      subHeader={
        <>
          <Typography variant="body2" style={{ display: 'inline-block' }}>
            Last Updated on&nbsp;
          </Typography>
          <Typography variant="body1" style={{ display: 'inline-block' }}>
            {formatDate(new Date(updatedAt))}
          </Typography>
        </>
      }
      body={
        <Box className={classes.body}>
          <ReactMarkdown source={body} className={classes.markdown} />
        </Box>
      }
    />
  );
};

export default TermsAndPrivacyContent;

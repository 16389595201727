import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import theme from '../../../../core-utils/theme';
import { Box, Divider, Typography } from '@material-ui/core';
import ListItems from '../ListItems';
import { TestResults } from '../../../_utils/constants';
import { formatDate } from '../../../../core-utils/helpers';
import { useIntl } from 'react-intl';
import ModalImage from 'react-modal-image';

const useStyles = makeStyles((theme) =>
  createStyles({
    testResult: {
      padding: theme.spacing(0, 2),
      borderRadius: theme.spacing(1),
      width: 'fit-content',
    },
    negative: {
      color: theme.palette.success.main,
      backgroundColor: theme.palette.success.light,
    },
    positive: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
    },
    image: {
      maxHeight: '200px !important',
    },
  }),
);

interface ITestResultCard {
  date: string;
  value: string;
  isLast: boolean;
  image?: string;
}

const TestResultCard: React.FC<ITestResultCard> = ({
  date,
  value,
  isLast,
  image,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        borderRadius="6px"
        boxShadow={theme.shadows[1]}
        width="100%"
        padding={4.5}
        style={
          value == TestResults.POSITIVE
            ? {
                borderTop: `4px solid ${theme.palette.error.main}`,
              }
            : {
                borderTop: `4px solid ${theme.palette.success.main}`,
              }
        }
      >
        <ListItems
          items={[
            {
              key: formatMessage({ id: 'tesDateLabel' }),
              value: formatDate(date),
            },
            {
              key: formatMessage({ id: 'resultLabel' }),
              value: (
                <Typography
                  variant="body1"
                  classes={{
                    colorSecondary: classes.negative,
                    colorPrimary: classes.positive,
                    root: classes.testResult,
                  }}
                  color={
                    value == TestResults.POSITIVE ? 'primary' : 'secondary'
                  }
                >
                  {value}
                </Typography>
              ),
            },
          ]}
        />
        {image && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt={6}
          >
            <ModalImage
              small={image}
              large={image}
              alt={value}
              hideZoom
              hideDownload
              className={classes.image}
            />
          </Box>
        )}
      </Box>
      {!isLast && <Divider orientation="vertical" style={{ height: '80px' }} />}
    </Box>
  );
};

export default TestResultCard;

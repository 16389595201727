import React from 'react';
import { updateConsent } from '../../../../services';
import { useHistory } from 'react-router-dom';
import useBack from '../../../_hooks/useBack/index';
import history from '../../../../core-utils/history';

export const useContactTracing = () => {
  const [open, setOpen] = React.useState(false);
  const { location } = useHistory<any>();
  useBack(() => {
    history.push('/settings', { patient: selected });
  });
  const [selected, setSelected] = React.useState<any>();
  const [toggleOn, setToggleOn] = React.useState(
    selected ? selected.consent?.given : false,
  );
  const [updateError, setUpdateError] = React.useState(false);
  const [toggleLoad, setToggleLoad] = React.useState(false);
  React.useEffect(() => {
    setToggleOn(selected?.consent?.given || false);
  }, [selected]);

  React.useEffect(() => {
    if (!location.state) {
      history.push('/settings');
    } else {
      setSelected(location.state.patient);
    }
  }, [location.state]);
  const handleConsent = (e) => {
    setToggleLoad(true);
    const checked = e.target.checked;
    const consent = { given: checked, timestamp: new Date().toUTCString() };
    updateConsent({
      patient: {
        skyflowId: selected.skyflowId,
        consent: consent,
      },
    })
      .then((res) => {
        const p = selected;
        p.consent = consent;
        setSelected(p);
        setToggleOn(checked);
        setToggleLoad(false);
        setUpdateError(false);
      })
      .catch((error) => {
        setToggleLoad(false);
        setUpdateError(true);
      });
  };
  return {
    toggleOn,
    selected,
    setToggleOn,
    open,
    setOpen,
    handleConsent,
    toggleLoad,
    updateError,
  };
};

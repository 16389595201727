import React from 'react';

export default (({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <filter id="v407oqmjfa">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.568627 0 0 0 0 0.564706 0 0 0 0 0.603922 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g
            filter="url(#v407oqmjfa)"
            transform="translate(-87 -517) translate(87 517)"
          >
            <g fill="#000" fill-rule="nonzero">
              <path
                d="M20.4 4.889h-3.6V3.056C16.8 1.368 15.457 0 13.8 0h-3.6c-1.657 0-3 1.368-3 3.056v1.833H3.6C1.612 4.889 0 6.53 0 8.556v9.777C0 20.358 1.612 22 3.6 22h16.8c1.988 0 3.6-1.642 3.6-3.667V8.556c0-2.025-1.612-3.667-3.6-3.667zM9.6 3.056c0-.338.269-.612.6-.612h3.6c.331 0 .6.274.6.612v1.833H9.6V3.056zm12 15.277c0 .675-.537 1.223-1.2 1.223H3.6c-.663 0-1.2-.548-1.2-1.223V8.556c0-.675.537-1.223 1.2-1.223h16.8c.663 0 1.2.548 1.2 1.223v9.777z"
                transform="translate(4 5)"
              />
              <path
                d="M12 9.167c-2.32 0-4.2 1.915-4.2 4.277 0 2.363 1.88 4.278 4.2 4.278 2.32 0 4.2-1.915 4.2-4.278 0-2.362-1.88-4.277-4.2-4.277zm0 6.11c-.994 0-1.8-.82-1.8-1.833 0-1.012.806-1.833 1.8-1.833s1.8.82 1.8 1.833-.806 1.834-1.8 1.834z"
                transform="translate(4 5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}) as React.FC<React.SVGProps<SVGSVGElement> & { size?: string }>;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Modal,
  Slide,
  Typography,
} from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import ProcedureInfoCard from '../../molecules/ProcedureInfoCard';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    button: {
      bottom: theme.spacing(7.5),
      position: 'absolute',
      width: 'calc(100% - 56px)',
    },
  }),
);

interface IProcedureInfo {
  isOpen: boolean;
  onClose: any;
  title: string;
  content: any;
  hideIndex?: boolean;
  iframeUrl?: string;
}

const ProcedureInfo: React.FC<IProcedureInfo> = ({
  isOpen,
  onClose,
  title,
  content,
  hideIndex,
  iframeUrl,
}) => {
  const classes = useStyles();
  return (
    <Modal open={isOpen}>
      <Container maxWidth="md" className={classes.container}>
        <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
          <Box
            maxWidth="inherit"
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            position="absolute"
            bgcolor={theme.palette.background.default}
          >
            <Box>
              <iframe
                width="100%"
                height="222px"
                src={iframeUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
            <Box px={7} flex="auto" pb={20.5} overflow="auto">
              <Box>
                <Typography variant="h1">{title}</Typography>
              </Box>
              <Box>
                {content &&
                  content.map((step, id) => {
                    return (
                      <ProcedureInfoCard
                        key={id}
                        id={hideIndex ? null : id + 1}
                        {...step}
                      />
                    );
                  })}
              </Box>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onClose}
              >
                <FormattedMessage id="goBack" />
              </Button>
            </Box>
          </Box>
        </Slide>
      </Container>
    </Modal>
  );
};

export default ProcedureInfo;

import * as React from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import DefaultTemplate from '../../templates/DefaultTemplate';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate/index';
import theme from '../../../../core-utils/theme';
import { useAccountDetails } from './hook';
import LoadingState from '../../../../common/_components/atoms/LoadingState';
import { createStyles, Theme } from '@material-ui/core/styles';
import errorIcon from '../../../../../public/assets/errorStateIcon.svg';
import ErrorCard from '../../molecules/ErrorCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: theme.spacing(1, 0),
    },
  }),
);
const AccountDetails = () => {
  const { phoneNumber, email, loading, error } = useAccountDetails();
  const classes = useStyles();
  if (loading) return <LoadingState />;
  else
    return (
      <DefaultTemplate
        body={
          <DefaultSubTemplate
            header={<Typography variant="h1">Account details</Typography>}
            body={
              error ? (
                <ErrorCard errorIcon={errorIcon} height={400} />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  marginTop={theme.spacing(2.5)}
                >
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        className={classes.text}
                        color="textSecondary"
                      >
                        Mobile Number
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.text}
                      >
                        {phoneNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        className={classes.text}
                        color="textSecondary"
                      >
                        Email Address
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.text}
                      >
                        {email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )
            }
          />
        }
      />
    );
};

export default AccountDetails;

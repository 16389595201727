import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import theme from '../../../../core-utils/theme';
import { useDeleteData } from './hook';
import LoadingState from '../../../../common/_components/atoms/LoadingState';

const useStyles = makeStyles((theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    text: {
      marginTop: theme.spacing(4),
    },
  }),
);

interface DeleteData {}

const DeleteData: React.FC<DeleteData> = () => {
  const classes = useStyles();
  const {
    handleDelete,
    loading,
    error,
    success,
    patient,
    redirecting,
  } = useDeleteData();
  if (!patient) {
    return <LoadingState />;
  } else
    return redirecting ? (
      <LoadingState loadingText="Successfully deleted patient details, Redirecting you to settings page" />
    ) : (
      <React.Fragment>
        {
          <DefaultSubTemplate
            header={
              <Typography variant="h1">
                <FormattedMessage id="deleteText" />
              </Typography>
            }
            subHeader={
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id="deleteDescription" />
              </Typography>
            }
            footerHeight={48}
            body={
              <>
                {error && (
                  <Box className={classes.text} display="flex">
                    <Typography
                      variant="overline"
                      style={{ color: theme.palette.error.main }}
                    >
                      Oh Snap! Something went wrong. Please check your internet
                      connectivity.
                    </Typography>
                  </Box>
                )}
                {success && (
                  <Box className={classes.text} display="flex">
                    <Typography
                      variant="overline"
                      style={{ color: theme.palette.success.main }}
                    >
                      {success}
                    </Typography>
                  </Box>
                )}
              </>
            }
            actionHandler={handleDelete}
            actionText={<FormattedMessage id="deleteActionText" />}
            actionProps={{
              classes: { root: classes.error },
              disabled: loading,
              startIcon: loading && (
                <CircularProgress color="inherit" size={20} />
              ),
            }}
          />
        }
      </React.Fragment>
    );
};

export default DeleteData;

import * as React from 'react';
import useBack from '../../../_hooks/useBack/index';
import history from '../../../../core-utils/history';
import { getAccountDetails } from '../../../../services';
import { useHistory } from 'react-router-dom';

export const useAccountDetails = () => {
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error,setError]=React.useState(false);
  const [selected, setSelected] = React.useState<any>();
  const { location } = useHistory<any>();

  useBack(() => history.push('/settings',{patient:selected}));

  React.useEffect(() => {
    if (!location.state) {
      history.push('/settings');
    } else {
      setSelected(location.state.patient);
    }
  }, [location.state]);
  React.useEffect(() => {
    setLoading(true);
    getAccountDetails()
      .then((res:any) => {
        setLoading(false);
        setEmail(res.data.emailAddress);
        setPhoneNumber(res.data.phoneNumber);
        setError(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return {
    phoneNumber,
    email,
    loading,
    error
  };
};

import React from 'react';
import { usePatients } from '../PatientsList/hook';
import { useHistory } from 'react-router-dom';

export const useSettings = () => {
  const { list, loading, error } = usePatients(true, '/profiles');
  const [patientList, setPatientList] = React.useState<any>([]);
  const [allPatients, setPatients] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(list[0]);
  const [loadingText,setLoadingText]=React.useState(false);
  const { location } = useHistory<any>();

  React.useEffect(() => {
    const patients = list.filter((x) => x.skyflowId !== selected.skyflowId);
    setPatientList(patients);
  }, [selected]);

  React.useEffect(() => {
    const selectedPatient = (location.state && location.state.patient) ? location.state.patient : list[0];
    const load=(location.state && location.state.load) ? true :false;
    setLoadingText(load);
    setSelected(selectedPatient);
    setPatients(list);
    const patients = list.filter(
      (x) => x.skyflowId !== selectedPatient.skyflowId,
    );
    setPatientList(patients);
  }, [list]);

  return {
    selected,
    setSelected,
    loading,
    open,
    setOpen,
    error,
    patientList,
    loadingText
  };
};

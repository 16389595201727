const verifyUserScreenMessages = {
    verifyHeader : 'Verify it\'s you',
    verifySubHeaderText : 'We will use your phone number to verify your identity. We will not share this with anyone without your permission.',
    phoneNumPlaceholder :'Enter phone number',
    sendVerificationCode : 'Send Verification Code',
    enterCode : 'Enter Code',
    checkSmsMessage : 'Check your SMS messages, we have sent you the code at \u2000',
    codeNotReceived : 'Didn’t receive the code?',
    resendOtp : 'Resend',
    submitOtp : 'Submit',
    incorrectOtpError : 'Incorrect OTP entered. Please enter again.',
    genericError : 'Something went wrong. Please enter again.',
};

export default verifyUserScreenMessages;
const driversLicenseScanScreen = {
  scanDLScreenHeader: 'Let’s create your profile',
  scanDLScreenSubHeaderText:
    'Save time by scanning the barcode of your driver’s license. We will autofill your name, birthday and address from it.',
  scanDLActionText: 'Scan Driver’s License',
  addManuallyActionText: 'Add Manually',
  orText: 'Or',
};

export default driversLicenseScanScreen;

import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorInfo: {
      display: 'inline-block',
      textAlign: 'center',
    },
    icon: {
      fontSize: '39px',
    },
  }),
);

export interface ErrorInfoProps {
  errorIcon: any;
  error?: string;
}

const ErrorInfo: React.FC<ErrorInfoProps> = ({
  errorIcon,
  error = <FormattedMessage id ='defaultErrorMsg'/>,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      className={classes.errorInfo}
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <img
          src={errorIcon}
          alt="Person standing along with warning symbol indicating error"
        />
      </Grid>
      <Grid item style={{ marginTop: theme.spacing(7.2) }}>
        <Typography className={classes.errorInfo} variant="h3">
          {error}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorInfo;

import React from 'react';

export default (({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  fill={color || '#007AFF'}
                  d="M24 7.6V6.4C24 5.075 22.806 4 21.333 4H10.667C9.194 4 8 5.075 8 6.4v1.2c-2.21 0-4 1.612-4 3.6v13.2C4 26.388 5.79 28 8 28h16c2.21 0 4-1.612 4-3.6V11.2c0-1.988-1.79-3.6-4-3.6zM10.667 6.4h10.666v4.8H10.667V6.4zm14.666 18c0 .663-.597 1.2-1.333 1.2H8c-.736 0-1.333-.537-1.333-1.2V11.2c0-.663.597-1.2 1.333-1.2v1.2c0 1.325 1.194 2.4 2.667 2.4h10.666c1.473 0 2.667-1.075 2.667-2.4V10c.736 0 1.333.537 1.333 1.2v13.2z"
                  transform="translate(-315 -113) translate(29 113) translate(286)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}) as React.FC<React.SVGProps<SVGSVGElement> & { size?: string }>;

import * as React from 'react';
import { deletePatients } from '../../../../services/index';
import _ from 'lodash';
import useBack from '../../../_hooks/useBack/index';
import history from '../../../../core-utils/history';
import { STORAGE } from '../../../../core-utils/routes';
import { useHistory } from 'react-router-dom';

export const useDeleteData = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [patient, setPatient] = React.useState<any>();
  const { location } = useHistory<any>();
  const [redirecting, setRedirecting] = React.useState(false);

  useBack(() => {
    history.push(STORAGE, { patient: patient });
  });

  React.useEffect(() => {
    if (!location.state) {
      history.push(STORAGE);
    } else {
      setPatient(location.state.patient);
    }
  }, [location.state]);

  const handleDelete = () => {
    if (patient) {
      setLoading(true);
      deletePatients(patient.skyflowId)
        .then((res) => {
          setLoading(false);
          setError(false);
          setSuccess('Successfully deleted patients with this phone number');
          setRedirecting(true);
          setTimeout(() => {
            history.push('/settings',{load:true});
            setRedirecting(false);
          }, 5000);
        })
        .catch((error) => {
          setSuccess('');
          setLoading(false);
          setError(true);
        });
    }
  };

  return {
    handleDelete,
    loading,
    error,
    success,
    patient,
    redirecting,
  };
};

import React from 'react';

interface IDetails {
  color?: string;
  size?: string;
}

const Details: React.FC<IDetails> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
          <path fill={color || '#007AFF'} id="0r244ro49a" 
            d="M18.75 17.3h-1.375V16c0-.718-.615-1.3-1.375-1.3s-1.375.582-1.375 1.3v1.3H13.25c-.76 0-1.375.582-1.375 1.3 0 .718.614 1.3 1.375 1.3h1.375v1.3c0 .718.614 1.3 1.375 1.3.76 0 1.375-.582 1.375-1.3v-1.3h1.375c.76 0 1.375-.582 1.375-1.3 0-.718-.615-1.3-1.375-1.3m4.735 9.1H8.513c-.42 0-.763-.291-.763-.65V6.25c0-.359.342-.65.763-.65H16v4.095c0 2.044 1.673 3.705 3.732 3.705h4.518v12.35c0 .359-.342.65-.765.65zM18.75 6.871l3.77 3.929h-2.788c-.542 0-.982-.495-.982-1.105V6.871zm7.892 4.355l-7.485-7.8c-.261-.271-.63-.426-1.019-.426H8.513C6.576 3 5 4.459 5 6.25v19.5C5 27.541 6.576 29 8.513 29h14.972C25.423 29 27 27.541 27 25.75V12.1c0-.324-.128-.634-.358-.874z"
            transform="translate(-85 -104) translate(28 104) translate(57)">
            </path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Details;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  IconButton,
  Link,
  Tooltip,
} from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { BACK_EVENT } from '../../../_utils/events';
import theme from '../../../../core-utils/theme';
import SettingsIcon from '../../../../../public/assets/settings.svg';
import history from '../../../../core-utils/history';
import { FormattedMessage } from 'react-intl';
import SettingsIconComponent from '../../../../core-utils/icons/SettingsIconComponent';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    backButton: {
      marginLeft: '-16px',
    },
    app: {
      padding: theme.spacing(0, 5),
      height: '100%',
    },
    imageText: {
      display: 'none',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  }),
);

interface IDefaultTemplate {
  header?: any;
  body: any;
  showBackButton?: any;
  showSettings?: boolean;
  settingsDisabled?: boolean;
}

const DefaultTemplate: React.FC<IDefaultTemplate> = ({
  header,
  body,
  showBackButton,
  showSettings,
  settingsDisabled
}) => {
  const classes = useStyles();

  const handleBackClick = () => {
    window.dispatchEvent(new CustomEvent(BACK_EVENT));
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box display="flex" className={classes.app} flexDirection="column">
        <Box>
          {showBackButton !== false && (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              height="44px"
              marginTop={theme.spacing(1)}
              justifyContent="space-between"
            >
              <IconButton
                aria-label="back"
                className={classes.backButton}
                onClick={handleBackClick}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              {showSettings === true && (
                <Link onClick={() =>!settingsDisabled &&  history.push('/settings')}>
                  <Tooltip title={<FormattedMessage id="settingsText" />}>
                  <>
                      <SettingsIconComponent
                        fill={
                          settingsDisabled
                            ? theme.palette.grey[300]
                            : theme.palette.common.black
                        }
                      />
                    </>
                  </Tooltip>
                </Link>
              )}
            </Box>
          )}
        </Box>
        {header && <Box>{header}</Box>}
        <Box flex="auto" marginBottom={theme.spacing(1)}>
          {body}
        </Box>
      </Box>
    </Container>
  );
};

export default DefaultTemplate;

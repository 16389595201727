import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import { PRIVACY, TERMS } from '../../../../core-utils/routes';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: 'center',
      marginTop: theme.spacing(3.5),
    },
    terms: {
      marginBottom: theme.spacing(0.5),
    },
    poweredBy: {
      marginBottom: theme.spacing(8.5),
    },
    inlineBlock: {
      display: 'inline-block',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  }),
);

interface IFooter {
  deviceID?: string;
}

const Footer: React.FC<IFooter> = ({ deviceID }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <div className={classes.terms} style={theme.typography.body2}>
        <Link to={`${PRIVACY}${deviceID ? `/?deviceID=${deviceID}` : ''}`}>
          <Typography variant="body1" className={classes.inlineBlock}>
            <FormattedMessage id="privacyPolicy" />
          </Typography>
        </Link>
          <FormattedMessage id="and" />
        <Link to={`${TERMS}${deviceID ? `/?deviceID=${deviceID}` : ''}`}>
          <Typography variant="body1" className={classes.inlineBlock}>
            <FormattedMessage id="termsOfService" />
          </Typography>
        </Link>
      </div>
      <Typography
        color="textSecondary"
        variant="overline"
        className={classes.poweredBy}
      >
         <FormattedMessage id="poweredBy" />
      </Typography>
    </Box>
  );
};

export default Footer;

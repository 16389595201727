import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import theme from '../../../../core-utils/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: 'center',
    },
  }),
);

interface ITitle {
  title: string;
  subTitle?: string;
}

const Title: React.FC<ITitle> = ({ title, subTitle }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid item>
        <Typography variant="h1">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">{subTitle}</Typography>
      </Grid>
    </Grid>
  );
};

export default Title;

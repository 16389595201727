import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { properties } from '../../core-utils/properties';
import history from '../../core-utils/history';
import {
  deleteCookie,
  getCookie,
  isSignedIn,
  setCookie,
  setUserToken,
} from '../helpers';

let recaptchaVerifier;

const firebaseConfig = {
  apiKey: properties.API_KEY,
  authDomain: properties.AUTH_DOMAIN,
  projectId: properties.PROJECT_ID,
  storageBucket: properties.STORAGE_BUCKET,
  messagingSenderId: properties.MESSAGING_SENDER_ID,
  appId: properties.APP_ID,
  measurementId: properties.MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

const testNumbers = [
  '+14085550096',
  '+14085557474',
  '+14085555196',
  '+12025550160',
];

export const useAuthProvider = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [isAuthenticated, setIsAuthenticated] = React.useState(isSignedIn);
  const [deviceID, setDeviceID] = React.useState(getCookie('device-id'));
  const [
    confirmationResult,
    setConfirmationResult,
  ] = React.useState<firebase.auth.ConfirmationResult>();

  const updateAuthentication = () => {
    if (isSignedIn()) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setPhoneNumber(user.phoneNumber || '');
      } else {
        setPhoneNumber('');
      }
    });
    updateAuthentication();
    history.listen(updateAuthentication);
  }, []);

  React.useEffect(() => {
    deviceID ? setCookie('device-id', deviceID) : deleteCookie('device-id');
  }, [deviceID]);

  React.useEffect(() => {
    firebase.auth().languageCode = 'en';
  }, []);

  async function submitPhoneNumber(phoneNumber: string) {
    if (testNumbers.includes(phoneNumber)) {
      firebase.auth().settings.appVerificationDisabledForTesting = true;
    }
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
    );

    setLoading(true);
    setError('');
    return firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
      .then(function (res) {
        setConfirmationResult(res);
        setPhoneNumber(phoneNumber);
      })
      .catch(function (error) {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const verifyPhoneNumber = (otp: string) => {
    if (otp && confirmationResult) {
      setLoading(true);
      setError('');
      confirmationResult
        .confirm(otp)
        .then(function (result) {
          // User signed in successfully.
          //@ts-ignore
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
              setUserToken(idToken);
              updateAuthentication();
              setLoading(false);
            })
            .catch(function (error) {
              setError(error);
              setLoading(false);
            });
        })
        .catch(function (error) {
          setError(error);
          setLoading(false);
        });
    }
  };

  return {
    submitPhoneNumber,
    verifyPhoneNumber,
    setDeviceID,
    loading,
    error,
    phoneNumber,
    isAuthenticated,
    deviceID,
    firebase,
  };
};

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import licenseImage from '../../../../../public/assets/license1.png';
import history from '../../../../core-utils/history';
import theme from '../../../../core-utils/theme';
import useBack from '../../../_hooks/useBack/index';
import { PROFILES } from '../../../../core-utils/routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      backgroundColor: '#f2f9ff',
      borderColor: '#d8dbde',
      borderRadius: '4px',
    },
    image: {
      maxWidth: '100%',
      padding: theme.spacing(1),
    },
  }),
);

interface ScanDriversLicense {}

const ScanDriversLicense: React.FC<ScanDriversLicense> = ({ ...rest }) => {
  const classes = useStyles();

  const handleScan = () => {
    history.push('/scan');
  };

  useBack(()=>{
    history.push(PROFILES, true)
  });

  return (
    <React.Fragment>
      {
        <DefaultSubTemplate
          header={
            <Typography variant="h1">
              <FormattedMessage id="scanDLScreenHeader" />
            </Typography>
          }
          subHeader={
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="scanDLScreenSubHeaderText" />
            </Typography>
          }
          footerHeight={48}
          body={
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box
                display="flex"
                justifyContent="center"
                border={2}
                className={classes.imageContainer}
              >
                <img
                  className={classes.image}
                  src={licenseImage}
                  alt="Drivers License Back Side with Highlighted Bar Code"
                />
              </Box>
              <Box my={5} position="absolute" bottom={0} width={'100%'}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleScan();
                  }}
                  color="primary"
                  style={{ marginTop: theme.spacing(60.5) }}
                >
                  <FormattedMessage id="scanDLActionText" />
                </Button>
                <Box m={4} display="flex" flexDirection="column">
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                  >
                    <FormattedMessage id="orText" />
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    history.push('/add-profile');
                  }}
                  color="primary"
                  style={{ marginTop: theme.spacing(2) }}
                >
                  <FormattedMessage id="addManuallyActionText" />
                </Button>
              </Box>
            </Box>
          }
        />
      }
    </React.Fragment>
  );
};

export default ScanDriversLicense;

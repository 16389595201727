import React from 'react';
import { hasMedia, steps } from '../../../../core-utils/constants';
import { deepCopy } from '../../../../core-utils/helpers';
import history from '../../../../core-utils/history';
import {
  PROFILES,
  REGISTER_DEVICE,
  THANK_YOU,
} from '../../../../core-utils/routes';
import useBack from '../../../_hooks/useBack';
import {
  step1Form,
  step2Form,
  step3Form,
  step4Form,
  step5Form,
  step6Form,
} from '../../../_utils/constants';
import { IForm } from '../../molecules/Form';
import { addPatient, getDevice, uploadImage } from '../../../../services';
import { buildAddProfileAndTest } from '../../../../services/builders';
import { useAuth } from '../../../../auth/Auth';
import { clearCookies } from '../../../../auth/helpers';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { states } from '../../../../core-utils/keys';
import useCamera from '../../../../common/_hooks/useCamera';
import { useFeatureFlags } from '../../../_hooks/useFeatureFlags';

export const useAddPatientSteps = (patientSkyflowId?: string) => {
  const [activeStep, setActiveStep] = React.useState(patientSkyflowId ? 2 : 0);
  const [imageData, setImageData] = React.useState({
    file: null as File | null,
    uri: null as string | null,
  });
  const { deviceID } = useAuth();
  const flags = useFeatureFlags();
  const [loading, setLoading] = React.useState(false);
  const { location } = useHistory<any>();
  const [completed, setCompleted] = React.useState(
    [!!patientSkyflowId, !!patientSkyflowId, false, false, false].concat(
      !patientSkyflowId ? [false] : [],
    ),
  );
  const [forms, setForms] = React.useState<IForm[]>(
    deepCopy(
      [step1Form, step2Form, step3Form, step4Form, step5Form].concat(
        !patientSkyflowId ? [step6Form] : [],
      ),
    ),
  );
  const [error, setError] = React.useState('');
  const { formatMessage } = useIntl();
  const {
    getFileFromImageUrl,
    getUrlFromFile,
    getCompressedImage,
  } = useCamera();

  useBack(() => {
    setError('');
    setActiveStep((step) => {
      if (step <= (patientSkyflowId ? 2 : 0)) {
        history.location.state && !(history.location.state instanceof Object)
          ? history.push(PROFILES, true)
          : hasMedia && flags.hasScanner
          ? history.push('/scan-dl')
          : history.push('/add-profile');
        return step;
      } else {
        return step - 1;
      }
    });
  });

  React.useEffect(() => {
    if (!deviceID) {
      clearCookies();
      history.push(REGISTER_DEVICE);
    }
  }, []);

  const constructFormData = async (res, request) => {
    request.test = {
      ...request.test,
      testOrdered: res.data.testOrdered,
      manufacturer: res.data.manufacturer,
      specimenSource: res.data.specimenSource,
    };

    const data = patientSkyflowId
      ? {
          test: {
            ...request.test,
            patientSkyflowId,
          },
          patient: undefined,
        }
      : request;

    if (!imageData.file) {
      return {
        formData: { ...data },
      };
    }
    try {
      const compressedImageUri =
        imageData.file.size > 1024 * 1024
          ? (await getCompressedImage(imageData.file)).file
          : imageData.file;

      return {
        formData: { ...data, fileType: imageData.file?.type },
        file: compressedImageUri,
      };
    } catch (e) {
      setError(formatMessage({ id: 'noimage' }));
      setLoading(false);
      return null;
    }
  };

  const onStepClick = (newStep = activeStep + 1) => {
    if ((patientSkyflowId && newStep < 2) || newStep == activeStep) {
      return;
    }

    setError('');
    setForms((forms) => {
      let hasError = false;

      if (forms[activeStep].stepType === 'form-group') {
        forms[activeStep].inputs = forms[activeStep].inputs.map((input) => {
          const error = getError(
            input.value,
            input.required,
            input.regex,
            input.errorMessageKey &&
              formatMessage({ id: input.errorMessageKey }),
          );

          hasError = hasError || !!error;

          return {
            ...input,
            error,
          };
        });
      }

      if (!hasError) {
        completed[activeStep] = true;
        setCompleted([...completed]);
      } else {
        completed[activeStep] = false;
        setCompleted([...completed]);
      }
      if (activeStep >= forms.length - 1 && newStep > activeStep) {
        onSubmit(forms);
      } else {
        setActiveStep(newStep);
      }

      return [...forms];
    });
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.target.files && event.target.files[0];
    file &&
      getUrlFromFile(file).then((uri: string) => {
        handleSetImageData(file, uri);
      });
  };

  const handleSetImageData = (file: File, uri: string) => {
    let ext = file.type.split('/').pop();
    if (ext) {
      ext = ext.toLowerCase();
      if (ext != 'jpeg' && ext != 'jpg' && ext != 'png') {
        setError(formatMessage({ id: 'uploadOnlyImage' }));
      } else if (file.size > 10 * 1024 * 1024) {
        //10MB
        setError(formatMessage({ id: 'fileSizeLarge' }));
      } else {
        setError('');
        setImageData({
          file,
          uri,
        });
      }
    }
  };

  const handleCapture = (uri: string) => {
    uri &&
      getFileFromImageUrl(uri).then((file) => {
        handleSetImageData(file, uri);
      });
  };

  const resetImage = () => {
    setImageData({
      file: null,
      uri: null,
    });
  };

  const onSubmit = (newForms: IForm[]) => {
    setLoading(true);
    setError('');
    const request = buildAddProfileAndTest([...newForms], deviceID);

    getDevice(deviceID, false)
      .then(async (res) => {
        const data = await constructFormData(res, request);
        data?.formData &&
          addPatient(data.formData)
            .then((res) => {
              imageData.file && res.data.url
                ? uploadImage(res.data.url, imageData.file.type, data.file)
                    .then(() => {
                      history.push(THANK_YOU, {
                        patientId: patientSkyflowId || res.data.patientId,
                        name: request.patient.name || history.location.state,
                      });
                    })
                    .catch((e) => {
                      setError(formatMessage({ id: 'noimage' }));
                      setLoading(false);
                    })
                : history.push(THANK_YOU, {
                    patientId: patientSkyflowId || res.data.patientId,
                    name: request.patient.name || history.location.state,
                  });
            })
            .catch((err) => {
              setError(formatMessage({ id: 'checkInternetErrorMsg' }));
              setLoading(false);
            });
      })
      .catch((err) => {
        setError(formatMessage({ id: 'checkInternetErrorMsg' }));
        setLoading(false);
      });
  };

  const handleChange = (
    step: number,
    input: number,
    event: React.ChangeEvent<HTMLInputElement>,
    value,
  ) => {
    event.persist && event.persist();
    setForms((forms) => {
      if (forms[step].inputs[0].type === 'radio') {
        forms[step].value = value;
      } else if (forms[step].inputs[input].type === 'checkbox') {
        forms[step].inputs[input].checked = value;
      } else {
        const inputValue = event.target.value;
        forms[step].inputs[input].error =
          getError(
            inputValue,
            forms[step].inputs[input].required,
            forms[step].inputs[input].regex,
            forms[step].inputs[input].errorMessageKey &&
              formatMessage({ id: forms[step].inputs[input].errorMessageKey }),
          ) || event.target.validationMessage;
        forms[step].inputs[input].value = inputValue;
      }

      return [...forms];
    });
  };

  const getError = (value, required, regex, errorMessage = '') => {
    if (required && !value) {
      return formatMessage({ id: 'fieldIsRequiredError' });
    } else if (regex && !regex?.test(value)) {
      return errorMessage || formatMessage({ id: 'invalidInputError' });
    } else {
      return '';
    }
  };

  React.useEffect(() => {
    const formSteps: IForm[] = deepCopy(forms);
    if (location.state && location.state instanceof Object) {
      formSteps[0].inputs[0].value =
        location.state &&
        (
          (location.state['fullName'] &&
            location.state['fullName'].split(',').join(' ')) ||
          (location.state['firstName'] &&
            location.state['firstName'].split(',').join(' ')) +
            (location.state['middleName']
              ? ' ' + location.state['middleName'] + ' '
              : ' ') +
            location.state['lastName']
        )
          .replace(/\s\s+/g, ' ')
          .trim();
      formSteps[0].inputs[1].value =
        location.state && location.state['dateOfBirth'];
      formSteps[0].inputs[2].value =
        location.state && location.state['addressStreet'];
      formSteps[0].inputs[3].value =
        location.state && location.state['addressCity'];
      formSteps[0].inputs[4].value =
        location.state && states[location.state['addressState']];
      formSteps[0].inputs[5].value =
        location.state &&
        location.state['addressPostalCode'] &&
        location.state['addressPostalCode'].slice(0, 5);
      formSteps[1].inputs[0].value =
        location.state &&
        formSteps[1].inputs[0].options &&
        formSteps[1].inputs[0].options[parseInt(location.state['sex']) - 1];
      for (let i = 0; i <= 5; i++) {
        formSteps[0].inputs[i].error = getError(
          formSteps[0].inputs[i].value,
          formSteps[0].inputs[i].required,
          formSteps[0].inputs[i].regex,
          formSteps[0].inputs[i].errorMessageKey &&
            formatMessage({ id: formSteps[0].inputs[i].errorMessageKey }),
        );
      }
      setForms(formSteps);
    }
  }, []);

  const isSubmitDisabled = () => {
    if (forms[activeStep].stepType === 'how-to-test') {
      return false;
    }
    if (forms[activeStep].stepType === 'radio-group') {
      return !Boolean(forms[activeStep].value);
    }
    if (forms[activeStep].stepType === 'radio-group-cards') {
      return !Boolean(
        forms[activeStep].value &&
          (!flags.canUploadImage || (imageData.file && imageData.uri)),
      );
    }
    if (forms[activeStep].stepType === 'checkbox-group') {
      return false;
    }
    if (forms[activeStep].stepType === 'form-group') {
      let disable = false;

      forms[activeStep].inputs.forEach((input) => {
        disable =
          disable || !!input.error || (!!input.required && !input.value);
      });

      return disable;
    }
    return true;
  };

  const isLastStep = () => {
    let laststep = true;
    completed.forEach((each, index) => {
      if (!each && index != activeStep) {
        laststep = false;
      }
    });
    return laststep && activeStep >= forms.length - 1;
  };

  return {
    variables: {
      steps: steps.slice(0, patientSkyflowId ? steps.length - 1 : steps.length),
      activeStep,
      submitDisabled: isSubmitDisabled(),
      loading,
      error,
      completed,
      isLastStep: isLastStep(),
      image: {
        src: imageData.uri,
        name: imageData.file?.name,
      },
    },
    handlers: {
      onStepClick,
      handleChange,
      onSubmit,
      handleImageUpload,
      handleCapture,
      resetImage,
    },
    forms,
  };
};

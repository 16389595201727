import { getCookie } from '../auth/helpers';
import { properties } from '../core-utils/properties';
import { setup } from 'axios-cache-adapter';

const apiEndpointUrl = properties.BEFFE_URL;

// Create cached axios instance with custom invalidate method
const api = setup({
  cache: {
    // Invalidate only when a specific option is passed through config
    invalidate: async (config: any, request) => {
      if (request.clearCacheEntry) {
        await config.store.removeItem(config.uuid);
      }
    },
    maxAge: 15 * 60 * 1000,
  },
  baseURL: apiEndpointUrl,
});

export const getDevice = (deviceID: string, noCache = true) => {
  return api.get('/device/' + deviceID, {
    clearCacheEntry: noCache,
  });
};

export const addPatient = (request: any, noCache = true) => {
  return api.post('/record', request, {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

export const getPatients = (noCache = true) => {
  return api.get('/patients', {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

export const downloadData = (patientId:string,noCache = true) => {
  return api.get('/download/'+patientId, {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

export const deletePatients = (patientId:string,noCache = true) => {
  return api.delete('/deletePatients/'+patientId, {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

export const getTests = (patientId: string, noCache = true) => {
  return api.get('/tests/' + patientId, {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

export const uploadImage = (url: string, ContentType: string, file: any) => {
  return api.put(url, file, { headers: { 'Content-Type': ContentType } });
};

export const updateConsent = (request: any, noCache = true) => {
  return api.post('/updateConsent', request, {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

export const getAccountDetails = (noCache = true) => {
  return api.get('/getAccountDetails', {
    clearCacheEntry: noCache,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getCookie('id-token'),
    },
  });
};

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import theme from '../../../../core-utils/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  }),
);

interface IIconDescription {
  icon: string;
  description: string;
}

const IconDescription: React.FC<IIconDescription> = ({ icon, description }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container} wrap="nowrap">
      <Grid item className={classes.icon}>
        <img src={icon} alt="Icon"></img>
      </Grid>
      <Grid item>
        <Typography variant="body2">{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default IconDescription;

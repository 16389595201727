import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Link,
  CircularProgress,
  Divider,
  FormHelperText,
} from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import NumberInput from '../../molecules/NumberInput';
import { OTP_LENGTH } from '../../../../core-utils/constants';
import PhoneInput, {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { useVerifyNumber } from './hook';
import Textfield from '../../../../common/_components/atoms/Textfield';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otpContainer: {
      margin: '5% auto',
      justifyContent: 'space-between',
    },
    otpInputValue: {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1.25),
    },
    otpInputEmpty: {
      borderBottom: `2px solid ${theme.palette.grey[200]}`,
    },
    otpInput: {
      width: '32px !important',
      height: '49px',
      fontSize: '36px',
      fontWeight: 900,
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
    inlineBlock: {
      display: 'inline-block',
    },
    text: {
      padding: `0 ${theme.spacing(2.5)}px`,
      marginTop: '0 !important',
    },
    textbox: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.spacing(5.5),
      paddingLeft: theme.spacing(5),
    },
    underline: {
      '&&&:before': {
        borderBottom: 'none !important',
      },
      '&&:after': {
        borderBottom: 'none !important',
      },
    },
    textInput: {
      padding: `${theme.spacing(1.75)}px 0`,
    },
    divider: {
      height: theme.spacing(7),
      margin: 'auto 0',
      color: theme.palette.grey[200],
      width: '1px',
    },
    input: {
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    helperText: {
      minHeight: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    reportIcon: {
      marginRight: theme.spacing(1.5),
      // marginTop: theme.spacing(0.5),
    },
  }),
);

interface IVerifyNumber {}

const VerifyNumber: React.FC<IVerifyNumber> = ({ ...rest }) => {
  const classes = useStyles();

  const { variables, handlers } = useVerifyNumber();
  const { numberSubmit, phoneNumber, loading, otp, error } = variables;
  const { sendOtp, setPhoneNumber, setOTP, handlePair } = handlers;
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      {!numberSubmit ? (
        <DefaultSubTemplate
          header={<Typography variant="h1"><FormattedMessage id='verifyHeader'/></Typography>}
          subHeader={
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id='verifySubHeaderText'/>
            </Typography>
          }
          body={
            <Box className={classes.textbox}>
              <PhoneInput
                limitMaxLength
                placeholder= {formatMessage({ id:'phoneNumPlaceholder'})}
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry={'US'}
                countrySelectProps={{
                  arrowComponent: () => null,
                  disabled: true,
                }}
                inputComponent={React.forwardRef((props, ref) => {
                  return (
                    <Box display="flex" width="calc(100% - 55px)" pl={1}>
                      <Divider className={classes.divider} />
                      <Box width="100%">
                        <Textfield
                          autoFocus={true}
                          withBackground
                          inputProps={{
                            ...props,
                          }}
                          InputProps={{
                            classes: {
                              underline: classes.underline,
                              root: classes.text,
                              input: classes.textInput,
                            },
                          }}
                          inputRef={ref}
                          fullWidth
                          size="small"
                        />
                      </Box>
                    </Box>
                  );
                })}
              />
            </Box>
          }
          actionText={<FormattedMessage id = 'sendVerificationCode'/>}
          actionHandler={() => sendOtp(phoneNumber)}
          actionProps={{
            disabled: loading || !isPossiblePhoneNumber(phoneNumber),
            startIcon: loading && (
              <CircularProgress color="inherit" size={20} />
            ),
          }}
        />
      ) : (
        <DefaultSubTemplate
          header={<Typography variant="h1"><FormattedMessage id ='enterCode'/></Typography>}
          subHeader={
            <>
              <Typography
                className={classes.inlineBlock}
                variant="subtitle1"
                color="textSecondary"
              >
                <FormattedMessage id ='checkSmsMessage'/>
              </Typography>
              <Typography
                className={classes.inlineBlock}
                variant="subtitle1"
                color="primary"
              >
                {formatPhoneNumberIntl(phoneNumber)}
              </Typography>
              <Typography
                className={classes.inlineBlock}
                variant="subtitle1"
                color="textSecondary"
              >
                .
              </Typography>
            </>
          }
          body={
            <Box>
              <NumberInput
                autoFocus
                isNumberInput
                length={OTP_LENGTH}
                className={classes.otpContainer}
                inputClassName={classes.otpInput}
                inputWithValueClassName={classes.otpInputValue}
                inputWithoutValueClassName={classes.otpInputEmpty}
                inputClasses={{
                  input: classes.input,
                  underline: classes.underline,
                }}
                onChangeOTP={(otp) => setOTP(otp)}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage id = 'codeNotReceived'/>
                </Typography>
                <Link variant="body2" onClick={() => sendOtp(phoneNumber)}>
                <FormattedMessage id = 'resendOtp'/>
                </Link>
              </Box>
              {error && (
                <FormHelperText>
                  <Typography
                    variant="body2"
                    color="error"
                    className={classes.helperText}
                    component="span"
                  >
                    <Box display="flex" alignItems="center">
                      {/* <ReportProblemOutlinedIcon
                        fontSize="small"
                        style={{ borderRadius: '10px' }}
                        className={classes.reportIcon}
                      /> */}
                      {error}
                    </Box>
                  </Typography>
                </FormHelperText>
              )}
            </Box>
          }
          actionText={<FormattedMessage id= 'submitOtp'/>}
          actionHandler={handlePair}
          actionProps={{
            disabled: loading || otp.length < OTP_LENGTH,
            startIcon: loading && (
              <CircularProgress color="inherit" size={20} />
            ),
          }}
        />
      )}
    </React.Fragment>
  );
};

export default VerifyNumber;

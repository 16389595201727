import {
  Typography,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '../Checkbox';

import React from 'react';
import Card from '../Card';

const useStyles = makeStyles((theme: Theme) => ({
  formLabelRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 0,
    padding: theme.spacing(4, 4.5),
  },
  formLabel: {
    marginRight: theme.spacing(1),
  },
}));

export interface ICheckboxCard
  extends Omit<FormControlLabelProps, 'control' | 'label' | 'onChange'> {
  checkboxProps?: CheckboxProps;
  content?: string;
  id?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

const CheckboxCard: React.FC<ICheckboxCard> = ({
  checkboxProps,
  content = '',
  onChange,
  id,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card>
      <FormControlLabel
        {...rest}
        control={<Checkbox id={id} onChange={onChange} {...checkboxProps} />}
        label={
          <Typography variant="body1" color="textPrimary">
            {content}
          </Typography>
        }
        labelPlacement="start"
        classes={{
          root: classes.formLabelRoot,
          label: classes.formLabel,
        }}
      />
    </Card>
  );
};

export default CheckboxCard;

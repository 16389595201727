import { IForm } from '../_components/molecules/Form';
import theme from '../../core-utils/theme';
import luciraImage from '../../../public/assets/luciraSmall.png';
import abottImage from '../../../public/assets/abottSmall.png';

export const REGEX_VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_VALID_NAME = /^(?:[A-za-z0-9,.'-]+)(?: [A-za-z0-9,.'-]+)+$/;
export const REGEX_VALID_ZIP_CODE = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const REGEX_VALID_ADDRESS = /^[A-za-z0-9 ,.'-]+$/;
export const REGEX_VALID_CITY = /^[A-Za-z]+(\s[A-Za-z]+)*\s*$/;
export const REGEX_DATE = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

export enum TestResults {
  POSITIVE = 'DETECTED',
  NEGATIVE = 'NOT_DETECTED',
}

export const step1Form: IForm = {
  titleKey: 'step1FormTitle',
  stepType: 'form-group',
  inputs: [
    {
      id: 'name',
      labelKey: 'name',
      value: '',
      required: true,
      regex: REGEX_VALID_NAME,
      errorMessageKey: 'nameError',
    },
    {
      id: 'dateOfBirth',
      labelKey: 'dateOfBirth',
      value: '',
      required: true,
      type: 'date',
      regex: REGEX_DATE,
      max: new Date().toISOString().slice(0, 10),
    },
    {
      id: 'streetAddress',
      labelKey: 'streetAddress',
      value: '',
      required: true,
      regex: REGEX_VALID_ADDRESS,
      errorMessageKey: 'addressInvalidChars',
    },
    {
      id: 'city',
      labelKey: 'city',
      value: '',
      required: true,
      regex: REGEX_VALID_CITY,
    },
    {
      id: 'state',
      labelKey: 'state',
      value: '',
      type: 'select',
      updateName: true,
      required: true,
      inline: true,
      options: [
        'ALABAMA',
        'ALASKA',
        'ARIZONA',
        'ARKANSAS',
        'CALIFORNIA',
        'COLORADO',
        'CONNECTICUT',
        'DELAWARE',
        'FLORIDA',
        'GEORGIA',
        'HAWAII',
        'IDAHO',
        'ILLINOIS',
        'INDIANA',
        'IOWA',
        'KANSAS',
        'KENTUCKY',
        'LOUISIANA',
        'MAINE',
        'MARYLAND',
        'MASSACHUSETTS',
        'MICHIGAN',
        'MINNESOTA',
        'MISSISSIPPI',
        'MISSOURI',
        'MONTANA',
        'NEBRASKA',
        'NEVADA',
        'NEW_HAMPSHIRE',
        'NEW_JERSEY',
        'NEW_MEXICO',
        'NEW_YORK',
        'NORTH_CAROLINA',
        'NORTH_DAKOTA',
        'OHIO',
        'OKLAHOMA',
        'OREGON',
        'PENNSYLVANIA',
        'RHODE_ISLAND',
        'SOUTH_CAROLINA',
        'SOUTH_DAKOTA',
        'TENNESSEE',
        'TEXAS',
        'UTAH',
        'VERMONT',
        'VIRGINIA',
        'WASHINGTON',
        'WEST_VIRGINIA',
        'WISCONSIN',
        'WYOMING',
      ],
    },
    {
      id: 'zipcode',
      labelKey: 'zipcode',
      value: '',
      required: true,
      regex: REGEX_VALID_ZIP_CODE,
      errorMessageKey: 'zipcodeError',
      inline: true,
    },
  ],
};

export const step2Form: IForm = {
  titleKey: 'step2FormTitle',
  stepType: 'form-group',
  inputs: [
    {
      id: 'sex',
      labelKey: 'sex',
      value: '',
      type: 'select',
      updateName: true,
      required: true,
      options: ['MALE', 'FEMALE', 'OTHER'],
    },
    {
      id: 'race',
      labelKey: 'race',
      value: '',
      type: 'select',
      required: true,
      updateName: true,
      options: [
        'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
        'ASIAN',
        'BLACK_OR_AFRICAN_NATIVE',
        'NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER',
        'WHITE',
        'RACE_UNKNOWN',
      ],
    },
    {
      id: 'ethnicity',
      labelKey: 'ethnicity',
      value: '',
      type: 'select',
      required: true,
      updateName: true,
      options: [
        'HISPANIC_OR_LATINO',
        'NON_HISPANIC_OR_LATINO',
        'ETHNICITY_UNKNOWN',
      ],
    },
    {
      id: 'emailAddress',
      labelKey: 'emailAddress',
      value: '',
      type: 'email',
      regex: REGEX_VALID_EMAIL,
      required: true,
    },
  ],
};

export const step3Form: IForm = {
  titleKey: 'step3FormTitle',
  stepType: 'checkbox-group',
  inputs: [
    {
      id: 'symptomatic',
      labelKey: 'symptomatic',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'firstTest',
      labelKey: 'firstTest',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'pregnant',
      labelKey: 'pregnant',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'employedInHealthcare',
      labelKey: 'employedInHealthcare',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'residenceInCongreateCare',
      labelKey: 'residenceInCongreateCare',
      type: 'checkbox',
      checked: false,
    },
  ],
};

export const step4Form: IForm = {
  titleKey: 'step4FormTitle',
  stepType: 'how-to-test',
  inputs: [],
};

export const step5Form: IForm = {
  titleKey: 'step5FormTitle',
  stepType: 'radio-group-cards',
  value: '',
  inputs: [
    {
      labelKey: 'negative',
      subLabelKey: 'negativeResult',
      value: TestResults.NEGATIVE,
      id: 'negative',
      type: 'radio',
      borderColor: theme.palette.success.main,
    },
    {
      labelKey: 'positive',
      subLabelKey: 'positiveResult',
      value: TestResults.POSITIVE,
      id: 'positive',
      type: 'radio',
      borderColor: theme.palette.error.main,
    },
  ],
};

export const step6Form: IForm = {
  titleKey: 'step6FormTitle',
  stepType: 'radio-group',
  value: '',
  inputs: [
    {
      labelKey: 'shareData',
      value: 'TRUE',
      id: 'share',
      type: 'radio',
    },
    {
      labelKey: 'dontShareData',
      value: 'FALSE',
      id: 'dontShare',
      type: 'radio',
    },
  ],
};

export const downloadOptions = {
  inputs: [
    {
      labelKey: 'CSV',
      value: 'CSV',
      id: 'csv',
      type: 'radio',
    },
    {
      labelKey: 'Excel',
      value: 'Excel',
      id: 'excel',
      type: 'radio',
    },
  ],
};

export const procedureSteps = {
  11345: [
    {
      description:
        'Hold Extraction Reagent bottle vertically. Hovering 1/2 inch above the TOP HOLE, slowly add 6 DROPS to the TOP HOLE of the swab well. DO NOT touch the card with the dropper tip while dispensing',
    },
    {
      description:
        'Insert sample or control swab into BOTTOM HOLE and firmly push upwards so that the swab tip is visible in the TOP HOLE.',
    },
    {
      description:
        'Rotate (twirl) swab shaft 3 times CLOCKWISE (to the right). Do not remove swab.',
    },
    {
      description:
        'Peel off adhesive liner from the right edge of the test card. Close and securely seal the card. Read result in the window 15 minutes after closing the card. In order to ensure proper test performance, it is important to read the result promptly at 15 minutes, and not before. Results should not be read after 30 minutes.',
    },
  ],
  22345: [
    {
      description:
        'Remove test unit from pouch 1. Open battery door and insert batteries. Check that Ready light is on. Open sample vial pouch 2. REMOVE sample vial seal. Then GENTLY set in test unit but do NOT push the vial down. Do not open swab until ready to use.',
    },
    {
      description:
        'Remove swab and hold with handle end. Do not set swab down. Tilt head back and gently insert swab tip until it is fully inside your nostril and you meet resistance. Once swab tip is fully inside nostril, roll the swab 5 times around the inside walls of your nostril. The swab should be touching the walls of the nostril as you rotate. Repeat swab step in other nostril.',
    },
    {
      description:
        'Insert swab into the sample vial until it touches the bottom. Mix sample by stirring around the sample vial 15 times. Discard swab. Snap cap closed and press vial down into test unit until it clicks. Ready light will start blinking when test is running. If Ready light is not blinking within 5 seconds, use palm of your hand to press down more firmly to start test. Do not move test unit once the test has started running. Wait 30 minutes.',
    },
    {
      description:
        'Test will be ready in 30 minutes. Done light will display when test is finished.',
    },
    {
      description:
        'After test is completed, place the test unit in plastic disposal bag and dispose all test kit materials in trash.',
    },
  ],
};

export const videoTutorial = {
  11345: 'https://www.youtube.com/watch?v=QpdOsXvh2nE',
  22345: 'https://www.youtube.com/watch?v=OpVMB_GfA0c',
};

export const videoTutorialEmbedded = {
  11345: 'https://www.youtube.com/embed/QpdOsXvh2nE',
  22345: 'https://www.youtube.com/embed/OpVMB_GfA0c',
};

export const deviceImages = {
  11345: abottImage,
  22345: luciraImage,
};

export const resultInterpretation = {
  11345: [
    {
      title: 'Negative Result',
      description:
        'A negative specimen will give a single pink/purple colored Control Line in the top half of the window, indicating a negative result. This Control Line means that the detection part of the test was done correctly, but no COVID-19 antigen was detected. Negative results should be treated as presumptive and confirmation with a molecular assay, if necessary, for patient management, may be performed.',
    },
    {
      title: 'Positive Result',
      description:
        'A positive specimen will give two pink/purple colored lines. This means that COVID-19 antigen was detected. Specimens with low levels of antigen may give a faint Sample Line. Any visible pink/purple colored line is positive.',
    },
    {
      title: 'Invalid Result',
      description:
        'If no lines are seen, or if just the Sample Line is seen, the assay is invalid. Invalid tests should be repeated.',
    },
  ],
  22345: [
    {
      title: 'Negative Result',
      description:
        'Negative light is displayed. A negative result means the virus that causes COVID-19 was not found in your sample. If you took this test while you have symptoms, a negative test result usually means that your current illness was not caused by COVID-19.',
    },
    {
      title: 'Positive Result',
      description:
        'Positive light is displayed. It is very likely you have COVID-19 and it is important to be under the care of a healthcare provider. It is likely you will be asked to isolate yourself at home to avoid spreading the virus to others.',
    },
    {
      title: 'Invalid Result',
      description:
        'If your test is invalid, all the lights will be blinking when the test is done in 30 minutes. This means something with the test did not work properly. In such cases, please contact us at 1-888-582-4724 and we will assist you.',
    },
  ],
};

export const testInfoTextPerDevice = {
  11345: 'Go ahead and take the test. Wait 15 minutes for the results to be visible. Then, click “Continue” below. See below for instructions on how to take the test.',
  22345: 'Go ahead and take the test. Wait 30 minutes for the results to be visible. Then, click “Continue” below. See below for instructions on how to take the test.',
};

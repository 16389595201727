import React from 'react';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';
import { VERIFY } from '../../core-utils/routes';
import { isSignedIn } from '../helpers';

interface ISecureRoute extends RouteProps {}

const SecureRoute: React.FC<ISecureRoute> = ({ ...rest }) => {
  const match = useRouteMatch(rest);
  if (!match) {
    return null;
  }
  return isSignedIn() ? <Route {...rest} /> : <Redirect to={VERIFY} />;
};

export default SecureRoute;

import {
  Box,
  Card as MuiCard,
  CardActionArea,
  CardProps,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(0),
    borderRadius: theme.spacing(1.5),
    '& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight': {
      opacity: 0,
    },
    '&:hover': {},
  },
  loading: {
    margin: 'auto',
  },
  loadingState: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
  },
  bottom: {
    color: theme.palette.grey[100],
  },
  top: {
    color: '#66afff',
    animationDuration: '3000ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  loadingText: {
    color: theme.palette.grey[500],
    fontWeight: 300,
    marginTop: theme.spacing(2),
  },
}));

export interface ICard extends CardProps {
  className?: string;
  borderColor?: string;
  loading?: boolean;
  error?: boolean;
  disableRipple?: boolean;
}

const Card: React.FC<ICard> = ({
  children,
  className,
  borderColor,
  loading,
  disableRipple,
  ...rest
}) => {
  const classes = useStyles();

  return loading ? (
    <Box display="flex" className={classes.loadingState}>
      <Box style={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={80}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          thickness={2}
          size={80}
          value={50}
        />
      </Box>
      <Typography variant="body1" className={classes.loadingText}>
        <FormattedMessage id ='loadingMessage' />
      </Typography>{' '}
    </Box>
  ) : (
    <MuiCard className={`${classes.root}`} {...rest}>
      <CardActionArea className={className} disableRipple={disableRipple}>
        {children}
      </CardActionArea>
      {borderColor && (
        <Box
          position="absolute"
          left={0}
          top={0}
          right={0}
          height="6px"
          style={
            borderColor
              ? {
                  border: '0px solid',
                  borderColor,
                  borderTopWidth: '4px',
                }
              : {}
          }
        ></Box>
      )}
    </MuiCard>
  );
};

export default Card;

import React from 'react';
import { useLocation } from 'react-router-dom';
const useQueryParam = (key: string) => {
  const [param, setParam] = React.useState('');

  const location = useLocation();
  React.useEffect(() => {
    if (key) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get(key)) setParam(searchParams.get(key) || '');
    }
  }, [location, key]);

  return {
    param,
  };
}

export default useQueryParam;

import React from 'react';

interface IShield {
  color?: string;
  size?: string;
}

const Shield: React.FC<IShield> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={color || '#007AFF'}
              d="M16 29.133c-.466.008-.926-.107-1.333-.333l-.4-.227C7.924 24.985 4.002 18.261 4 10.973v-.186c-.009-.962.5-1.853 1.333-2.334L14.667 3.2c.825-.476 1.841-.476 2.666 0l9.334 5.253c.832.48 1.342 1.372 1.333 2.334v.186c-.007 7.294-3.94 14.019-10.293 17.6l-.4.227c-.4.221-.85.336-1.307.333zm0-23.6l-9.333 5.254v.186c.004 6.328 3.418 12.163 8.933 15.267l.4.227.4-.227c5.515-3.104 8.929-8.939 8.933-15.267v-.186L16 5.533z"
              transform="translate(-219 -104) translate(28 104) translate(191)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Shield;

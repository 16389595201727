import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderWidth: '2px',
      borderStyle: 'Dashed',
      borderColor: '#979797',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      cursor: 'pointer',
      borderRadius: '4px',
      backgroundColor: '#f2f9ff',
      flexDirection: 'column',
    },
    input: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0,
      cursor: 'pointer',
      width: '100%',
    },
  }),
);

interface IUploadButton extends BoxProps {
  label: string;
  isFileInput?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  Icon?: any;
}

const UploadButton: React.FC<IUploadButton> = ({
  label,
  isFileInput,
  onChange,
  Icon,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Box height="100px" className={classes.root} position="relative" {...rest}>
      {isFileInput && (
        <input
          type="file"
          accept="image/x-png,image/jpeg"
          onChange={onChange}
          className={classes.input}
        />
      )}
      <Icon />
      <Typography color="textSecondary" variant="body2">
        {label}
      </Typography>
    </Box>
  );
};

export default UploadButton;

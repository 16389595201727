import React from 'react';
import {
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import { Step, StepButton, StepConnector, StepLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0),
      backgroundColor: theme.palette.background.default,
    },
    iconContainer: {
      padding: theme.spacing(0),
    },
    horizontal: {
      padding: theme.spacing(0),
    },
  }),
);

const Connector = withStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[200],
    borderWidth: 1,
  },
}))(StepConnector);

interface IStep {
  label: string;
  icon: any;
}

interface IStepper {
  steps: IStep[];
  activeStep: any;
  className?: string;
  onStepClick?: any;
  completed: boolean[];
}

const Stepper: React.FC<IStepper> = ({
  steps,
  activeStep,
  className,
  onStepClick,
  completed,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MuiStepper
      activeStep={activeStep}
      connector={<Connector />}
      classes={{ root: classes.root }}
      className={className}
    >
      {steps.map((each, index) => (
        <Step key={each.label} classes={{ horizontal: classes.horizontal }}>
          <StepButton
            onClick={onStepClick.bind(null, index)}
            completed={completed[index]}
          >
            <StepLabel
              StepIconComponent={each.icon}
              StepIconProps={{
                color:
                  activeStep == index
                    ? theme.palette.grey[500]
                    : completed[index]
                    ? theme.palette.primary.main
                    : theme.palette.grey[200],
              }}
              classes={{
                iconContainer: classes.iconContainer,
              }}
            />
          </StepButton>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import theme from '../../../../core-utils/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: theme.spacing(12),
    },
  }),
);

interface IProductIcons {
  icons: string[];
}

const ProductIcons: React.FC<IProductIcons> = ({ icons }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      justify="space-evenly"
    >
      {icons.map((icon: any, index) => (
        <Grid key={index}>
          <img src={icon} alt="Home screen icon"></img>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductIcons;

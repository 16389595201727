import React from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import FormInputField from '../../../../common/_components/atoms/Textfield';
import CheckboxCardList from '../CheckboxCardList';
import RadioButtonList from '../RadioButtonList';
import Select from '../../../../common/_components/atoms/Select';
import { getDisplayName } from '../../../../core-utils/helpers';
import HowToTest from '../HowToTest';
import ConsentImage from '../../../../../public/assets/consentLarge.svg';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      top: 'calc(50% - 19px)',
    },
  }),
);

export interface IFormInput {
  id: string;
  labelKey?: string;
  placeholder?: string;
  subLabelKey?: string;
  value?: string;
  options?: string[];
  checked?: boolean;
  type?: string;
  required?: boolean;
  borderColor?: string;
  error?: string;
  regex?: RegExp;
  max?: string;
  errorMessageKey?: string;
  updateName?: boolean;
  inline?: boolean;
}
export interface IForm {
  titleKey: string;
  inputs: IFormInput[];
  handleChange?: any;
  stepType?: string;
  value?: string;
  disabled?: boolean;
  showError?:boolean;
}

const Form: React.FC<IForm> = ({
  titleKey,
  inputs,
  handleChange,
  stepType,
  value,
  disabled,
  showError
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1" style={{ marginBottom: theme.spacing(4.5) }}>
          {<FormattedMessage id={titleKey} />}
        </Typography>
      </Grid>
      {stepType === 'form-group' &&
        inputs.map((inputProps, index) => (
          <Grid item xs={inputProps.inline ? 6 : 12} key={inputProps.id}>
            {inputProps.type == 'select' ? (
              <Select
                key={inputProps.id}
                id={inputProps.id}
                value={inputProps.value}
                onChange={handleChange.bind(null, index)}
                input={
                  <FormInputField
                    label={formatMessage({ id: inputProps.labelKey })}
                    helperText={inputProps.error}
                    required={inputProps.required}
                  />
                }
                inputProps={{ classes: { icon: classes.icon } }}
              >
                {inputProps.options &&
                  inputProps.options.map((option) => (
                    <MenuItem value={option} key={option}>
                      {inputProps.updateName ? getDisplayName(option) : option}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <FormInputField
                key={inputProps.id}
                label={formatMessage({ id: inputProps.labelKey })}
                id={inputProps.id}
                value={inputProps.value}
                helperText={inputProps.error}
                errorText={showError?inputProps.error:''}
                required={inputProps.required}
                onChange={handleChange.bind(null, index)}
                type={inputProps.type}
                inputProps={{ max: inputProps.max }}
              />
            )}
          </Grid>
        ))}

      {stepType === 'checkbox-group' && (
        <Grid item xs={12}>
          <CheckboxCardList
            cards={inputs.map((input, index) => ({
              ...input,
              content: formatMessage({ id: input.labelKey }),
              value: formatMessage({ id: input.labelKey }),
              onChange: handleChange.bind(null, index),
            }))}
          />
        </Grid>
      )}

      {stepType === 'how-to-test' && (
        <Grid item xs={12}>
          <HowToTest />
        </Grid>
      )}

      {stepType?.includes('radio-group') && (
        <>
          {!stepType.includes('card') && (
            <>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img src={ConsentImage} alt="Image of consent" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ lineHeight: theme.spacing(6) + 'px' }}
                >
                  {formatMessage({ id: 'dataShareInfo' })}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <RadioButtonList
              inputs={inputs.map((input) => ({
                ...input,
                content: formatMessage({ id: input.labelKey }),
                subContent:
                  input.subLabelKey && formatMessage({ id: input.subLabelKey }),
                subLabel: undefined,
                disabled,
              }))}
              isCard={stepType.includes('card')}
              value={value}
              onChange={handleChange.bind(null, -1)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Form;

import React from 'react';
import useBack from '../../../_hooks/useBack';
import history from '../../../../core-utils/history';
import { useAuth } from '../../../../auth/Auth';
import { useIntl } from 'react-intl';

export const useVerifyNumber = () => {
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [numberSubmit, setNumberSubmit] = React.useState(false);
  const [otp, setOTP] = React.useState<string>('');
  const [error, setError] = React.useState('');
  const {
    submitPhoneNumber,
    verifyPhoneNumber,
    loading,
    isAuthenticated,
    error: authError,
  } = useAuth();
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (!authError) {
      setError('');
    } else {
      if (authError?.code === 'auth/invalid-verification-code') {
        setError(formatMessage({id : 'incorrectOtpError'}));
      } else {
        setError(formatMessage({id : 'genericError'}));
      }
    }
  }, [authError]);

  useBack(() => {
    setNumberSubmit((current) => {
      if (current) {
        return false;
      } else {
        history.goBack();
        return current;
      }
    });
    setError('');
  });

  const sendOtp = (phoneNumber: string) => {
    submitPhoneNumber(phoneNumber)
      .then(() => {
        setNumberSubmit(true);
      })
      .catch((e) => {
        //TODO: error below textfield
      });
  };

  const handlePair = () => {
    verifyPhoneNumber(otp);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      history.replace('/profiles');
    }
  }, [isAuthenticated]);

  return {
    variables: {
      phoneNumber,
      numberSubmit,
      loading,
      otp,
      error,
    },
    handlers: {
      sendOtp,
      setPhoneNumber,
      setNumberSubmit,
      setOTP,
      handlePair,
    },
  };
};

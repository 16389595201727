import * as React from 'react';
import { Typography } from '@material-ui/core';
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import { COLORS_ARRAY } from '../../../../core-utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const getRandomColor = () => {
  return COLORS_ARRAY[Math.floor(Math.random() * COLORS_ARRAY.length)];
};

const getUniqueColorFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return COLORS_ARRAY[Math.abs(hash % COLORS_ARRAY.length)];
};

interface IAvatarProps extends AvatarProps {
  image?: string;
  initial?: string;
  small?: string;
  large?: string;
  size?: string;
  getRandomColors?: boolean;
  fontSize?: string;
}

const Avatar: React.FC<IAvatarProps> = ({
  image,
  initial,
  size,
  getRandomColors,
  fontSize,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiAvatar
      className={classes.root}
      src={image ? image : undefined}
      style={{
        height: size,
        width: size,
        backgroundColor: getRandomColors
          ? initial
            ? getUniqueColorFromString(initial)
            : getRandomColor()
          : '',
      }}
      {...rest}
    >
      {initial && (
        <Typography style={{ fontSize: fontSize || 'initial' }}>
          {initial}
        </Typography>
      )}
    </MuiAvatar>
  );
};


export default Avatar;
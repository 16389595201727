import { Grid, RadioGroup, RadioGroupProps } from '@material-ui/core';

import React from 'react';
import RadioButton, {
  IRadioButton,
} from '../../../../common/_components/atoms/RadioButton';

export interface IRadioButtonList extends RadioGroupProps {
  inputs: IRadioButton[];
  isCard?: boolean;
}

const RadioButtonList: React.FC<IRadioButtonList> = ({
  inputs,
  isCard,
  ...rest
}) => {
  return (
    <RadioGroup {...rest}>
      <Grid container direction="row" spacing={3}>
        {inputs.map((input) => (
          <Grid item xs={12} key={input.id}>
            <RadioButton {...input} isCard={isCard} />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
};

export default RadioButtonList;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
    }),
);

interface ITEM {
    key: string;
    value: any;
}

interface IListItems {
    items: ITEM[]
}

const ListItems: React.FC<IListItems> = ({ items }) => {
    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            {
                items.map(item => {
                    return (
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body2">{item.key + ':'}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1">{item.value}</Typography>
                            </Grid>
                        </>
                    )
                })
            }
        </Grid>

    )
};

export default ListItems;
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FEATURE_KEYS } from '../../../core-utils/constants';

export const useFeatureFlags = () => {
  const flags = useFlags();

  return {
    hasScanner: flags[FEATURE_KEYS.scanner],
    canUploadImage: flags[FEATURE_KEYS.scanner],
  };
};

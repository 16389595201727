import React from 'react';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import TermsAndPrivacyContent from '../../_components/organisms/TermsAndPrivacyContent';

interface ITermsAndPrivacy {}

const TermsAndPrivacy: React.FC<ITermsAndPrivacy> = () => {
  return <DefaultTemplate body={<TermsAndPrivacyContent />} />;
};

export default TermsAndPrivacy;

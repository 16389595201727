import React from 'react';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import ScanDriversLicense from '../../_components/organisms/ScanDriversLicense';

interface IScanDriversLicensePage {}

const ScanDriversLicensePage: React.FC<IScanDriversLicensePage> = () => {
  return <DefaultTemplate body={<ScanDriversLicense />} />;
};

export default ScanDriversLicensePage;

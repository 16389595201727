import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, Link, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorInfo: {
      color: theme.palette.grey[300],
      textAlign: 'center',
      display: 'inline-block',
    },
  }),
);

export interface ErrorDetailsProps {
  error?: string;
}

const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  error = <FormattedMessage id ='defaultErrorDetailMsg'/>,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={9} className={classes.errorInfo}>
        <Typography variant="subtitle1">{error}</Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorDetails;

import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Button,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PatientCard from '../../molecules/PatientCard';
import { usePatients } from './hook';
import ErrorCard from '../../molecules/ErrorCard';
import errorIcon from '../../../../../public/assets/errorStateIcon.svg';
import nopatients from '../../../../../public/assets/nopatients.svg';
import AddIcon from '@material-ui/icons/Add';
import theme from '../../../../core-utils/theme';
import { FormattedMessage } from 'react-intl';
import DefaultTemplate from '../../templates/DefaultTemplate/index';
import { useFeatureFlags } from '../../../_hooks/useFeatureFlags';
import { hasMedia } from '../../../../core-utils/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    retryButton: {
      width: '159px',
    },
    bottom: {
      color: theme.palette.grey[100],
    },
    top: {
      color: '#66afff',
      animationDuration: '3000ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
    loadingState: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      flexDirection: 'column',
    },
    loadingText: {
      color: theme.palette.grey[500],
      fontWeight: 300,
      marginTop: theme.spacing(2),
    },
  }),
);

interface IPatientsList {}

const PatientsList: React.FC<IPatientsList> = () => {
  const {
    list,
    loading,
    error,
    deviceID,
    onPatientClick,
    onViewTimeLine,
  } = usePatients();
  const classes = useStyles();
  const flags = useFeatureFlags();

  return (
    <DefaultTemplate
      showSettings={list.length?true:false}
      settingsDisabled={error || loading}
      body={
        error ? (
          <>
            <Box height="100%">
              <ErrorCard errorIcon={errorIcon} height="400px" />
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.retryButton}
                  onClick={() => window.location.reload()}
                >
                  <FormattedMessage id="retry" />
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box py={4} height="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={4}
              >
                <Typography variant="subtitle1">
                  {deviceID ? (
                    <FormattedMessage id="addProfileHeader" />
                  ) : (
                    <FormattedMessage id="selectUser" />
                  )}
                </Typography>
                {deviceID && (
                  <Link
                    variant="body1"
                    style={{
                      color: loading
                        ? theme.palette.grey[300]
                        : theme.palette.primary.main,
                      display: 'flex',
                    }}
                    href={
                      hasMedia && flags.hasScanner ? '/scan-dl' : '/add-profile'
                    }
                  >
                    <AddIcon
                      style={{ marginLeft: theme.spacing(0.5), width: '18px' }}
                    />
                    <FormattedMessage id="addFamilyLabel" />
                  </Link>
                )}
              </Box>
              <Box minHeight="calc(100% - 38px)">
                {loading ? (
                  <Box display="flex" className={classes.loadingState}>
                    <Box style={{ position: 'relative' }}>
                      <CircularProgress
                        variant="determinate"
                        className={classes.bottom}
                        size={80}
                        thickness={2}
                        value={100}
                      />
                      <CircularProgress
                        variant="indeterminate"
                        className={classes.top}
                        classes={{
                          circle: classes.circle,
                        }}
                        thickness={2}
                        size={80}
                        value={50}
                      />
                    </Box>
                    <Typography variant="body1" className={classes.loadingText}>
                      <FormattedMessage id="loadingMessage" />
                    </Typography>
                  </Box>
                ) : list.length ? (
                  <Grid container spacing={4} direction="row">
                    {list.map((each) => (
                      <Grid item xs={12} md={6} key={each.skyflowId}>
                        <PatientCard
                          name={each.name}
                          onViewTimeLine={onViewTimeLine.bind(null, each)}
                          onClick={onPatientClick.bind(null, each)}
                          borderColor={theme.palette.grey[200]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <ErrorCard
                    height={400}
                    errorIcon={nopatients}
                    errorInfo="No Patient Added"
                    errorDetails=""
                  />
                )}
              </Box>
            </Box>
          </>
        )
      }
    />
  );
};

export default PatientsList;

import { Checkbox as MuiCheckBox, CheckboxProps } from '@material-ui/core';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import CheckboxIcon from '../../icons/Checkbox';
import CheckboxCheck from '../../icons/CheckboxCheck';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

export interface ICheckbox extends CheckboxProps {}

const Checkbox: React.FC<ICheckbox> = ({ ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <MuiCheckBox
      checkedIcon={
        <CheckboxCheck color={theme.palette.primary.main} size="30" />
      }
      icon={<CheckboxIcon color={theme.palette.grey[200]} size="30" />}
      {...rest}
      className={`${classes.root} ${rest.className || ''}`}
    />
  );
};

export default Checkbox;

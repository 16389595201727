import React from 'react';

export const useProcedureInfoModal = () => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);

  const toggleInfoModal = () => {
    setInfoModalOpen(!infoModalOpen);
  };

  return {
    infoModalOpen,
    toggleInfoModal,
  };
};

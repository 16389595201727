import React from 'react';

export default (({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color || '#C1C0C8'}>
          <g>
            <g>
              <path
                d="M18.08 2.666c.376.002.735.161.987.44l7.253 8c.222.245.346.564.347.894v14c-.01.895-.376 1.749-1.017 2.374-.64.625-1.502.97-2.397.96H8.747c-.895.01-1.757-.335-2.397-.96-.64-.625-1.006-1.48-1.017-2.374V6c.01-.895.376-1.749 1.017-2.374.64-.625 1.502-.97 2.397-.96zM16 5.333H8.747c-.188-.01-.372.054-.512.179S8.01 5.812 8 6v20c.01.188.095.363.235.488.14.125.324.19.512.179h14.506c.188.01.372-.054.512-.179s.225-.3.235-.488V13.333h-4.387c-2.044-.058-3.658-1.755-3.613-3.8v-4.2zm4 16c.736 0 1.333.597 1.333 1.334 0 .736-.597 1.333-1.333 1.333h-8c-.736 0-1.333-.597-1.333-1.333 0-.737.597-1.334 1.333-1.334zM16 16c.736 0 1.333.597 1.333 1.333 0 .737-.597 1.334-1.333 1.334h-4c-.736 0-1.333-.597-1.333-1.334 0-.736.597-1.333 1.333-1.333zm2.667-9.333v2.866c-.022.282.07.56.255.774.186.213.45.343.731.36h2.667l-3.653-4z"
                transform="translate(-241 -104) translate(28 104) translate(213)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}) as React.FC<React.SVGProps<SVGSVGElement> & { size?: string }>;

import * as React from 'react';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import {
  Typography,
  Box,
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PersonIcon from '../../../../../public/assets/personIcon.svg';
import PrivacyIcon from '../../../../../public/assets/privacyIcon.svg';
import theme from '../../../../core-utils/theme';
import history from '../../../../core-utils/history';
import useBack from '../../../_hooks/useBack';
import { FormattedMessage } from 'react-intl';
import { useSettings } from './hook';
import ErrorCard from '../../molecules/ErrorCard';
import errorIcon from '../../../../../public/assets/errorStateIcon.svg';
import LoadingState from '../../../../common/_components/atoms/LoadingState';
import Avatar from '../../atoms/Avatar/index';
import {
  getUserInitialFromName,
  textClipper,
} from '../../../../core-utils/helpers';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '56px',
      marginLeft: -theme.spacing(7),
      marginRight: -theme.spacing(7),
      cursor: 'pointer',
      boxShadow: 'none',
      borderTop: '1px solid #dfe3eb',
    },
    image: {
      padding: theme.spacing(0, 2, 0, 6),
    },
    retryButton: {
      width: '159px',
    },
    avatarRoot: {
      marginRight: '-4px',
      zIndex: 1,
    },
    popup: {
      textAlign: 'center',
    },
    close: {},
    dialog: {
      padding: '0px 0px 0px 20px',
    },
    paperWidthSm: {
      maxHeight: '370px',
      width: '100%',
    },
    paper: {
      overflow: 'auto',
    },
    gutters: {
      paddingLeft: '20px',
    },
    root: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    content: {
      padding: '0px 0px 30px 0px',
    },
    button: {
      '&:hover': {
        backgroundColor: '#f5f8fa',
      },
    },
  }),
);
const Settings = () => {
  const classes = useStyles();
  const {
    selected,
    setSelected,
    loading,
    open,
    setOpen,
    patientList,
    error,
    loadingText,
  } = useSettings();
  useBack(() => {
    history.push('/profiles');
  });
  if (error) {
    return (
      <Box height="100%">
        <ErrorCard errorIcon={errorIcon} height="400px" />
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.retryButton}
            onClick={() => window.location.reload()}
          >
            <FormattedMessage id="retry" />
          </Button>
        </Box>
      </Box>
    );
  } else
    return loading ? (
      <LoadingState
        loadingText={
          loadingText
            ? 'Successfully deleted patient details, Redirecting you to settings page'
            : ''
        }
      />
    ) : selected?(
      <DefaultSubTemplate
        header={
          <Box>
            <Box display="flex" alignItems="center">
              <Avatar
                initial={getUserInitialFromName(selected?.name)}
                getRandomColors={true}
                size={'32px'}
                fontSize="12px"
                classes={{ root: classes.avatarRoot }}
              />
              <Box padding="0px 8px 0px 15px">
                <Typography variant="body1">
                  {textClipper(selected?.name?.toUpperCase(), 20)}
                </Typography>
              </Box>
              <ExpandMoreIcon
                style={{
                  fill: theme.palette.grey[300],
                  height: '18px',
                  width: '18px',
                }}
                onClick={() => setOpen(true)}
              />
            </Box>

            <Box marginTop={theme.spacing(2)}>
              <Typography variant="h1">
                <FormattedMessage id="settingsText" />
              </Typography>
            </Box>
          </Box>
        }
        body={
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginTop={theme.spacing(2.5)}
          >
            <Card
              className={classes.card}
              onClick={() =>
                history.push('/accountSettings', { patient: selected })
              }
            >
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                borderBottom="1px solid #efefef"
              >
                <img className={classes.image} src={PersonIcon} />
                <Typography variant="subtitle1" color="textPrimary">
                  <FormattedMessage id="account" />
                </Typography>
              </Box>
            </Card>
            <Card
              onClick={() =>
                history.push('/privacySettings', { patient: selected })
              }
              className={classes.card}
              style={{ borderBottom: '1px solid #dfe3eb' }}
            >
              <Box
                display="flex"
                alignItems="center"
                height="100%"
                borderBottom="1px solid #efefef"
              >
                <img className={classes.image} src={PrivacyIcon} />
                <Typography variant="subtitle1" color="textPrimary">
                  <FormattedMessage id="privacyText" />
                </Typography>
              </Box>
            </Card>

            <Dialog
              onClose={() => {
                setOpen(false);
              }}
              open={open}
              className={classes.popup}
              classes={{ paperWidthSm: classes.paperWidthSm }}
            >
              <DialogTitle
                className={classes.close}
                classes={{ root: classes.dialog }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h3" color="textPrimary">
                    Profiles
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon style={{ fill: theme.palette.grey[300] }} />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <ListItem
                  key={selected.skyflowId}
                  button
                  onClick={() => {
                    setSelected(selected);
                    setOpen(false);
                  }}
                  classes={{
                    gutters: classes.gutters,
                    root: classes.root,
                    button: classes.button,
                  }}
                >
                  <Avatar
                    initial={getUserInitialFromName(selected?.name)}
                    getRandomColors={true}
                    size={'32px'}
                    fontSize="12px"
                    classes={{ root: classes.avatarRoot }}
                  />
                  <ListItemText
                    style={{ marginLeft: '15px' }}
                    primary={
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'normal' }}
                      >
                        {selected.name.toUpperCase()}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
                {patientList.map((patient) => (
                  <ListItem
                    key={patient.skyflowId}
                    button
                    onClick={() => {
                      setSelected(patient);
                      setOpen(false);
                    }}
                    classes={{
                      gutters: classes.gutters,
                      root: classes.root,
                      button: classes.button,
                    }}
                  >
                    <Avatar
                      initial={getUserInitialFromName(patient.name)}
                      getRandomColors={true}
                      size={'32px'}
                      fontSize="12px"
                      classes={{ root: classes.avatarRoot }}
                    />
                    <ListItemText
                      style={{ marginLeft: '15px' }}
                      primary={
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'normal' }}
                        >
                          {patient.name.toUpperCase()}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </DialogContent>
            </Dialog>
          </Box>
        }
      />
    ):<></>;
};

export default Settings;

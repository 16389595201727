import { Box, BoxProps, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import RightArrow from '../../icons/RightArrow';
import Card from '../Card';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(5, 4.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    textDecoration: 'none',
  },
}));

export interface IInfoCard extends BoxProps {
  text: string;
}

const InfoCard: React.FC<IInfoCard> = ({ text, ...rest }) => {
  const classes = useStyles();

  return (
    <Card>
      <Box className={classes.box} {...rest}>
        <Typography variant="body1" color="textPrimary">
          {text}
        </Typography>
        <RightArrow />
      </Box>
    </Card>
  );
};

export default InfoCard;

import React from 'react';
import { useAuth } from '../../../../auth/Auth';
import { hasMedia } from '../../../../core-utils/constants';
import history from '../../../../core-utils/history';
import { TEST_RESULTS } from '../../../../core-utils/routes';
import { getPatients } from '../../../../services';
import useBack from '../../../_hooks/useBack';
import { useFeatureFlags } from '../../../_hooks/useFeatureFlags';

export const usePatients = (cache=true,route='/') => {
  const [list, setList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<any>('');
  const { deviceID } = useAuth();
  const flags = useFeatureFlags();

  useBack(() => {
    history.push(route);
  });

  React.useEffect(() => {
    getPatients(cache)
      .then((res) => {
        if (res.data.rows.length === 0) {
          if(deviceID){
          history.location.state
            ? history.replace('/')
            : hasMedia && flags.hasScanner
            ? history.replace('/scan-dl')
            : history.replace('/add-profile', true);
          }
          else{
            history.replace('/profiles');
          }
        } else {
          setList(res.data.rows);
        }
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onPatientClick = (patient: any, event: any) => {
    deviceID
      ? history.push('/add-profile/' + patient.skyflowId, patient.name)
      : onViewTimeLine(patient, event);
  };

  const onViewTimeLine = (patient: any, event: any) => {
    event.stopPropagation();
    event.preventDefault();
    history.push(TEST_RESULTS, {
      patientId: patient.skyflowId,
      name: patient.name
    });
  };

  return {
    list,
    loading,
    error,
    deviceID,
    onPatientClick,
    onViewTimeLine,
  };
};

import React from 'react';

export default (({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <filter id="zdtayrhv5a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.568627 0 0 0 0 0.564706 0 0 0 0 0.603922 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g
            filter="url(#zdtayrhv5a)"
            transform="translate(-256 -516) translate(256 516)"
          >
            <g fill="#000" fill-rule="nonzero" transform="translate(4 4)">
              <rect
                width="24"
                height="2.824"
                rx="1"
                transform="rotate(180 12 1.412)"
              />
              <path
                d="M20.676 1.324h3.648c.552 0 1 .447 1 1v1c0 .552-.448 1-1 1h-3.648c-.552 0-1-.448-1-1v-1c0-.553.448-1 1-1z"
                transform="rotate(90 22.5 2.824)"
              />
              <path
                d="M-.324 1.324h3.648c.552 0 1 .447 1 1v1c0 .552-.448 1-1 1H-.324c-.552 0-1-.448-1-1v-1c0-.553.448-1 1-1z"
                transform="rotate(90 1.5 2.824)"
              />
              <path d="M6 14.118c-.472 0-.917-.21-1.2-.565-.239-.3-.341-.676-.285-1.047.056-.37.267-.705.585-.93l6-4.235c.527-.362 1.243-.362 1.77 0l6 3.981c.673.45.834 1.327.36 1.963-.23.308-.58.517-.975.581-.394.064-.8-.024-1.125-.242L12 10.22l-5.1 3.614c-.26.184-.575.283-.9.283z" />
              <path d="M12 24c-.828 0-1.5-.632-1.5-1.412V11.294c0-.78.672-1.412 1.5-1.412s1.5.632 1.5 1.412v11.294c0 .78-.672 1.412-1.5 1.412z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}) as React.FC<React.SVGProps<SVGSVGElement> & { size?: string }>;

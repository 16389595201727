import addPatientScreenMessages from './en/addPatientScreenMessages';
import deviceInfoScreenMessages from './en/deviceInfoScreenMessages';
import resultSubmittedScreenMessages from './en/resultSubmittedScreenMessages';
import welcomeScreenMessages from './en/welcomeScreenMessages';
import registerDeviceScreenMessages from './en/registerDeviceScreenMessages';
import verifyUserScreenMessages from './en/verifyUserScreenMessages';
import globalMessages from './en/globalMessages';
import patientListScreenMessages from './en/patientListScreenMessages';
import howToTestScreenMessages from './en/howToTestScreenMessages';
import testRecordStepsMessages from './en/testRecordStepsMessages';
import driversLicenseScanScreen from './en/driversLicenseScanScreen';
import settingsScreenMessages from './en/settingsScreenMessages';

export type languages = 'en' | 'fr' | 'es';
const messages = {
  en: {
    ...globalMessages,
    ...welcomeScreenMessages,
    ...registerDeviceScreenMessages,
    ...deviceInfoScreenMessages,
    ...resultSubmittedScreenMessages,
    ...verifyUserScreenMessages,
    ...addPatientScreenMessages,
    ...patientListScreenMessages,
    ...howToTestScreenMessages,
    ...testRecordStepsMessages,
    ...driversLicenseScanScreen,
    ...settingsScreenMessages,
  },
};
export default messages;

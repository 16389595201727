import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, IconButton } from '@material-ui/core';
import Webcam from 'react-webcam';
import { FiberManualRecord, HighlightOff } from '@material-ui/icons';
import { pxToRem } from '../../../../core-utils/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: 'transparent',
      margin: theme.spacing(0),
      maxWidth: '100%',
    },
    root: {
      backgroundColor: theme.palette.common.black,
    },
    icon1: {
      color: theme.palette.common.white,
      fontSize: pxToRem(52),
    },
    icon2: {
      color: theme.palette.common.white,
      fontSize: pxToRem(60),
    },
    background: {
      backgroundColor: '#ffffff55',
    },
    button: {
      height: '48px',
      width: '48px',
      padding: 0,
    },
  }),
);

interface ICamera {
  onImageCapture: any;
  Component: any;
  props: any;
}

const Camera: React.FC<ICamera> = ({
  onImageCapture = () => {},
  Component,
  props,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const webcamRef = React.useRef<Webcam>(null);
  const videoConstraints = {
    facingMode: 'environment',
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCameraOpen = () => {
    setOpen(true);
  };

  const capture = React.useCallback(() => {
    const imageSrc =
      webcamRef && webcamRef.current && webcamRef.current.getScreenshot();
    onImageCapture(imageSrc);
    console.log(imageSrc);
  }, [webcamRef]);

  return (
    <Box>
      <Dialog
        open={open}
        classes={{ paper: classes.paper, root: classes.root }}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <Box
          position="fixed"
          width="132px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          alignSelf="center"
          color="#fff"
          bottom={24}
        >
          <IconButton onClick={onClose} className={classes.button}>
            <HighlightOff fontSize="large" className={classes.icon1} />
          </IconButton>
          <IconButton
            onClick={capture}
            className={`${classes.button} ${classes.background}`}
          >
            <FiberManualRecord fontSize="large" className={classes.icon2} />
          </IconButton>
        </Box>
      </Dialog>
      <Component {...props} onClick={onCameraOpen} />
    </Box>
  );
};

export default Camera;

import React from 'react';

interface ICheckCircle {
  color?: string;
  size?: string;
}

const CheckCircle: React.FC<ICheckCircle> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '32'}
      height={size || '32'}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              fill={color || '#007AFF'}
              d="M16 2.667c1.064.004 2.124.13 3.16.373.489.087.889.437 1.038.91.15.473.025.99-.325 1.343-.35.352-.866.48-1.34.334-.83-.19-1.68-.288-2.533-.294-2.84-.009-5.565 1.125-7.56 3.147-3.037 3.055-3.938 7.637-2.285 11.614 1.654 3.978 5.537 6.57 9.845 6.573 5.891 0 10.667-4.776 10.667-10.667 0-.736.597-1.333 1.333-1.333s1.333.597 1.333 1.333c0 7.364-5.97 13.333-13.333 13.333-3.502-.008-6.858-1.403-9.333-3.88-3.82-3.798-4.978-9.522-2.936-14.507C5.773 5.961 10.613 2.695 16 2.666zm8.293 5.786c.495-.514 1.307-.549 1.843-.077.536.472.605 1.281.157 1.837L16.96 20.88c-.243.277-.591.442-.96.453-.354.002-.695-.137-.947-.386l-4-4c-.522-.523-.522-1.37 0-1.894.523-.522 1.37-.522 1.894 0l3.053 3z"
              transform="translate(-315 -105) translate(28 104) translate(287 1)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckCircle;

import React from 'react';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import RegisterDevice from '../../_components/organisms/RegisterDevice';

interface IRegisterDevicePage {}

const RegisterDevicePage: React.FC<IRegisterDevicePage> = () => {
  return <DefaultTemplate body={<RegisterDevice />} />;
};

export default RegisterDevicePage;

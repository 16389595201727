import {
  Typography,
  Grid,
  FormControlLabel,
  RadioProps,
  FormControlLabelProps,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import React from 'react';
import Card from '../Card';
import Radio from '../Radio';

const useStyles = makeStyles((theme: Theme) => ({
  formLabelRoot: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 0,
  },
  card: {
    padding: theme.spacing(4, 4.5),
    justifyContent: 'space-between',
  },
  formLabel: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

export interface IRadioButton
  extends Omit<FormControlLabelProps, 'control' | 'label'> {
  radioProps?: RadioProps;
  content?: string;
  subContent?: string;
  borderColor?: string;
  isCard?: boolean;
}

const RadioButton: React.FC<IRadioButton> = ({
  radioProps,
  content = '',
  subContent,
  id,
  borderColor,
  isCard,
  ...rest
}) => {
  const classes = useStyles();

  const formControl = (
    <FormControlLabel
      {...rest}
      control={<Radio id={id} {...radioProps} />}
      label={
        isCard ? (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="body1" color="textPrimary">
                {content}
              </Typography>
            </Grid>
            {subContent && (
              <Grid item>
                <Typography variant="overline" color="textSecondary">
                  {subContent}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography variant="subtitle1">{content}</Typography>
        )
      }
      labelPlacement={isCard ? 'start' : 'end'}
      classes={{
        root: `${classes.formLabelRoot} ${isCard && classes.card} `,
        label: classes.formLabel,
      }}
    />
  );

  return isCard ? (
    <Card borderColor={borderColor}>{formControl}</Card>
  ) : (
    formControl
  );
};

export default RadioButton;

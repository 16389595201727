import * as React from 'react';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { Typography, Box, Card } from '@material-ui/core';
import theme from '../../../../core-utils/theme';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useBack from '../../../_hooks/useBack';
import history from '../../../../core-utils/history';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '56px',
      marginLeft: -theme.spacing(7),
      marginRight: -theme.spacing(7),
      cursor: 'pointer',
      boxShadow: theme.shadows[11],
      borderTop: '1px solid #dfe3eb',
    },
    image: {
      padding: theme.spacing(0, 2, 0, 6),
    },
    text: {
      marginTop: theme.spacing(4),
    },
  }),
);

const Storage = () => {
  const classes = useStyles();

  useBack(() => {
    history.push('/privacySettings',{patient:patient});
  });
  const { location } = useHistory<any>();
  const [patient, setPatient] = React.useState<any>();
  React.useEffect(() => {
    if (!location.state) {
      history.push('/settings');
    } else {
      setPatient(location.state.patient);
    }
  }, [location.state]);
  return (
    <>
      <DefaultSubTemplate
        header={
          <Typography variant="h1">
            <FormattedMessage id="storedData" />
          </Typography>
        }
        body={
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginTop={theme.spacing(2.5)}
          >
            <Card
              className={classes.card}
              onClick={() =>
                history.push('/downloadData', { patient: patient })
              }
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height="100%"
                style={{ borderBottom: '1px solid #dfe3eb' }}
                padding="28px"
              >
                <Typography variant="subtitle1" color="textPrimary">
                  <FormattedMessage id="downloadText" />
                </Typography>
                <NavigateNextOutlinedIcon
                  style={{ fill: theme.palette.grey[300] }}
                />
              </Box>
            </Card>
            <Typography
              className={classes.text}
              variant="subtitle1"
              color="textSecondary"
            >
              <FormattedMessage id="downloadDescription" />
            </Typography>
            <Card
              className={classes.card}
              style={{
                marginTop: theme.spacing(6),
              }}
              onClick={() => history.push('/deleteData',{ patient: patient })}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height="100%"
                style={{ borderBottom: '1px solid #dfe3eb' }}
                padding="28px"
              >
                <Typography variant="subtitle1" color="textPrimary">
                  <FormattedMessage id="deleteText" />
                </Typography>
                <NavigateNextOutlinedIcon
                  style={{ fill: theme.palette.grey[300] }}
                />
              </Box>
            </Card>
            <Typography
              className={classes.text}
              variant="subtitle1"
              color="textSecondary"
            >
              <FormattedMessage id="deleteDescription" />
            </Typography>
          </Box>
        }
      />
    </>
  );
};

export default Storage;

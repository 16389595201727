import React from 'react';

export default (({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={size || '30'}
      height={size || '30'}
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color || '#C1C0C8'}>
          <g>
            <g>
              <path
                d="M6.25 16.25h14.825l-4.537 5.45c-.442.532-.37 1.32.162 1.762.532.442 1.32.37 1.762-.162l6.25-7.5c.043-.06.08-.122.113-.188 0-.062.063-.1.088-.162.056-.143.086-.296.087-.45-.001-.154-.03-.307-.087-.45 0-.063-.063-.1-.088-.163-.033-.065-.07-.127-.113-.187l-6.25-7.5c-.237-.286-.59-.45-.962-.45-.292 0-.575.101-.8.287-.256.212-.416.517-.447.848-.03.33.072.66.285.915l4.537 5.45H6.25C5.56 13.75 5 14.31 5 15s.56 1.25 1.25 1.25z"
                transform="translate(-299 -520) translate(28 500) translate(271 20)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}) as React.FC<React.SVGProps<SVGSVGElement> & { size?: string }>;

import { Input, InputProps } from '@material-ui/core';
import React from 'react';
import usePrevious from '../../../../core-utils/hooks/usePrevious';

export interface SingleOTPInputProps extends InputProps {
  focus?: boolean;
}

const SingleOTPInput: React.FC<SingleOTPInputProps> = ({
  focus,
  autoFocus,
  ...rest
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);
  React.useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <Input type="number" inputRef={inputRef} {...rest} />;
};

export default SingleOTPInput;

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import Textfield from '../../../../common/_components/atoms/Textfield';
import { useRegisterDevice } from './hook';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: `0 ${theme.spacing(5)}px`,
      marginTop: '0 !important',
    },
    textInput: {
      padding: `${theme.spacing(2.5)}px 0`,
    },
    textBorder: {
      borderRadius: '22px',
    },
    imageContainer: {
      backgroundColor: '#f2f9ff',
      borderColor: '#d8dbde',
      borderRadius: '4px',
    },
    image: {
      maxWidth: '200px',
      marginTop: -theme.spacing(11.125),
    },
    underline: {
      '&&&:before': {
        borderBottom: 'none !important',
      },
      '&&:after': {
        borderBottom: 'none !important',
      },
    },
  }),
);

interface IRegisterDevice {}

const RegisterDevice: React.FC<IRegisterDevice> = ({ ...rest }) => {
  const classes = useStyles();
  const { variables, handlers } = useRegisterDevice();
  const { serialNo } = variables;
  const { setSerailNo, handleContinue } = handlers;
  return (
    <DefaultSubTemplate
      header={
        <Typography variant="h1">
          <FormattedMessage id="deviceQuery" />
        </Typography>
      }
      subHeader={
        <Typography variant="subtitle1" color="textSecondary">
          <FormattedMessage id="serialNumberLabel" />
        </Typography>
      }
      body={
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Textfield
            autoFocus
            value={serialNo}
            withBackground
            onChange={(event) => setSerailNo(event.target.value)}
            className={classes.textBorder}
            InputProps={{
              classes: {
                underline: classes.underline,
                root: classes.text,
                input: classes.textInput,
              },
            }}
          />
          {/* <Box paddingTop={7.5} paddingBottom={4.5}>
            <Typography variant="subtitle1">
              <FormattedMessage id="serialNumberPosInfo"/>             
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            border={2}
            className={classes.imageContainer}
          >
            <img
              className={classes.image}
              src={deviceRegister}
              width="200px"
              height="250px"
              alt="Image with QR code with serial number"
            />
          </Box> */}
        </Box>
      }
      actionText={<FormattedMessage id="continue" />}
      actionHandler={handleContinue}
      actionProps={{
        disabled: !serialNo,
      }}
    />
  );
};

export default RegisterDevice;

const tokenExpiryTime = 30 * 60 * 1000;

export function isSignedIn(): boolean {
  if (getCookie('id-token')) {
    return true;
  }
  return false;
}

export function setUserToken(token: string): void {
  const date = new Date();
  date.setTime(date.getTime() + tokenExpiryTime);
  document.cookie =
    'id-token' + '=' + token + '; expires=' + date.toUTCString() + '; path=/';
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    while (ca[i].charAt(0) == ' ') {
      ca[i] = ca[i].substring(1);
    }
    if (ca[i].indexOf(name) == 0) {
      return ca[i].substring(name.length, ca[i].length);
    }
  }
  return '';
}

export function setCookie(name, value) {
  document.cookie = name + '=' + value + '; Path=/;';
}

export function deleteCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function clearCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export const REFERRER_PATH = 'referrerpath';

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) =>
  createStyles({
    loadingState: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      flexDirection: 'column',
    },
    top: {
      color: '#66afff',
      animationDuration: '3000ms',
      position: 'absolute',
      left: 0,
    },
    loadingText: {
      color: theme.palette.grey[500],
      fontWeight: 300,
      marginTop: theme.spacing(2),
      textAlign:'center'
    },
    circle: {
      strokeLinecap: 'round',
    },
    bottom: {
      color: theme.palette.grey[100],
    },
  }),
);

interface ILoadingState {
  loadingText?: string;
}

const LoadingState: React.FC<ILoadingState> = ({ loadingText }) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.loadingState}>
      <Box style={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={80}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          thickness={2}
          size={80}
          value={50}
        />
      </Box>
      <Typography variant="body1" className={classes.loadingText}>
        {loadingText ? loadingText : <FormattedMessage id="loadingMessage" />}
      </Typography>
    </Box>
  );
};

export default LoadingState;

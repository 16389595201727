import { HOME } from '../../../../core-utils/routes';
import history from '../../../../core-utils/history';
import useBack from '../../../_hooks/useBack';
import React from 'react';

export const useRegisterDevice = () => {
  const [serialNo, setSerailNo] = React.useState('');

  const handleContinue = () => {
    //TODO: register serial number
    history.push(`/device/${serialNo}`)
  };
  useBack(() => {
    history.push(HOME);
  });

  return {
    variables: {
      serialNo,
    },
    handlers: {
      setSerailNo,
      handleContinue,
    },
  };
};

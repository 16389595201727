import React from 'react';
import { Box, Button } from '@material-ui/core';

interface IDefaultSubTemplate {
  header?: any;
  subHeader?: any;
  body: any;
  actionText?: any;
  actionHandler?: any;
  actionProps?: any;
  footer?: any;
  footerHeight?: number;
}

const DefaultSubTemplate: React.FC<IDefaultSubTemplate> = ({
  header,
  subHeader,
  body,
  actionText,
  actionHandler,
  actionProps,
  footer,
  footerHeight,
}) => {
  return (
    <Box minHeight="100%" width="100%" py={4} position="relative">
      <Box>{header}</Box>
      {subHeader && (
        <Box pt={2} pb={7.5}>
          {subHeader}
        </Box>
      )}
      <Box pb={footerHeight || 18.5}>{body}</Box>
      <Box my={5} position="absolute" bottom={0} width={'100%'}>
        {actionText && (
          <Button
            variant="contained"
            color="primary"
            onClick={actionHandler}
            {...actionProps}
          >
            {actionText}
          </Button>
        )}
        {footer}
      </Box>
    </Box>
  );
};

export default DefaultSubTemplate;

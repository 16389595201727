import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { Link as Navlink } from 'react-router-dom';
import FightCorona from '../../../../../public/assets/fight-corona.svg';
import {
  CORONA_INFO_LINK
} from '../../../../core-utils/constants';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import theme from '../../../../core-utils/theme';
import history from '../../../../core-utils/history';
import { HOME, TEST_RESULTS } from '../../../../core-utils/routes';
import DefaultSubTemplate from '../../templates/DefaultSubTemplate';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export interface IResultSubmitted {}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: '7.5%',
      paddingRight: '7.5%',
      textAlign: 'center',
    },
    image: {
      marginTop: theme.spacing(22.5),
      width: '44.5%',
      height: '14.8%',
      alignSelf: 'center',
      maxWidth: 500,
    },
    resultsSubmitted: {
      marginTop: theme.spacing(7.5),
    },
    subTitle: {
      marginTop: theme.spacing(2),
    },
    navlink: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(12)
    },
    link: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
  }),
);

const ResultSubmitted: React.FC<IResultSubmitted> = () => {
  const classes = useStyles();
  const { location } = useHistory();

  const onClick = () => {
    history.push(TEST_RESULTS, location.state);
  };

  return (
    <DefaultSubTemplate
      body={
        <Grid
          container
          justify="center"
          direction="column"
          className={classes.container}
        >
          <img
            src={FightCorona}
            className={classes.image}
            alt={'Fight corona image'}
          ></img>
          <Grid
            className={classes.resultsSubmitted}
            container
            direction={'column'}
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h1"><FormattedMessage id= "backToHome"/></Typography>
            </Grid>
            <Grid item className={classes.subTitle}>
              <Typography variant="subtitle1">
                <FormattedMessage id= "moreInformation"/>
                <Link href={CORONA_INFO_LINK} target="_blank" rel="noopener">
                  {CORONA_INFO_LINK}
                </Link>
              </Typography>
            </Grid>
            <Grid item className={classes.link}>
              <Link variant="subtitle1" onClick={onClick} className={classes.navlink}>
                <FormattedMessage id= "viewTestTimeline"/>
                <ArrowForward style={{ marginLeft: theme.spacing(2) }} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      }
      actionText={<FormattedMessage id= "backToHome"/>}
      actionHandler={() => {
        history.push(HOME);
      }}
    />
  );
};

export default ResultSubmitted;

import React from 'react';
import DefaultTemplate from '../../_components/templates/DefaultTemplate';
import VerifyNumber from '../../_components/organisms/VerifyNumber';

interface IVerifyUser {}

const VerifyUser: React.FC<IVerifyUser> = () => {
  return <DefaultTemplate body={<VerifyNumber />} />;
};

export default VerifyUser;

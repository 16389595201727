const deviceInfoScreenMessages = {
    deviceInfoHeader :'Let’s review your device information',
    deviceNotFound : 'Device not found! Please provide a valid serial number.',
    proceedMessage : 'Looks Good, Continue',
    procedureHeader : 'Sample Test Procedure',
    testTypeLabel : 'Test type:',
    serialNumLabel : 'Serial number:',
    testLabel : 'Test ordered:',
    testAdvisory : 'Learn how to take the test',
};

export default deviceInfoScreenMessages;
import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '38px',
      height: '20px',
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(17px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.success.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&.Mui-disabled': {
        color: theme.palette.common.white,
      },
      '&$focusVisible $thumb': {
        color: theme.palette.success.main,
        border: `6px solid ${theme.palette.common.white}`,
      },
    },
    thumb: {
      width: '18px',
      height: '18px',
    },
    disabled: {
      width: '18px',
      height: '18px',
      borderStyle: 'dashed',
      border: '2px solid grey',
      animation: '$rotation 2000ms infinite linear',
    },
    track: {
      borderRadius: 20 / 2,
      backgroundColor: '#dfe3eb',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    '@keyframes rotation': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
    checked: {},
    focusVisible: {},
  }),
);

interface ISwitcher {
  checkedState: any;
  onToggleChange?: any;
  disabled?: boolean;
}
const Switcher: React.FC<ISwitcher> = ({
  checkedState,
  onToggleChange,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={checkedState}
      onChange={onToggleChange}
      disabled={disabled}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        // thumb: disabled ? classes.disabled : classes.thumb,
        thumb:classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
};

export default Switcher;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Typography } from '@material-ui/core';
import theme from '../../../../core-utils/theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: '20px',
      height: '20px',
      marginRight: theme.spacing(2),
    },
  }),
);

interface IProcedureInfoCard {
  id: number;
  title?: string;
  description: string;
}

const ProcedureInfoCard: React.FC<IProcedureInfoCard> = ({
  id,
  title,
  description,
}) => {
  const classes = useStyles();
  return (
    <Box
      borderRadius="6px"
      bgcolor={theme.palette.primary.contrastText}
      boxShadow={theme.shadows[1]}
      marginTop={4}
      padding={4.5}
    >
      <Box display="flex">
        {id && (
          <Avatar className={classes.avatar}>
            <Typography variant="caption" style={{ fontSize: '14px' }}>
              {id}
            </Typography>
          </Avatar>
        )}
        <Box>
          {title && <Typography variant="subtitle1">{title}</Typography>}
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProcedureInfoCard;

import { Radio as MuiRadio, RadioProps } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0),
    color: theme.palette.grey[200]
  },
}));

export interface IRadio extends RadioProps {}

const Radio: React.FC<IRadio> = ({ ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <MuiRadio color="primary" size="medium"  {...rest} className={`${classes.root} ${rest.className || ''}`} />
  );
};

export default Radio;

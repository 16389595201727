import firebase from 'firebase/app';
import 'firebase/database';
import React from 'react';
import { useAuthProvider } from './hook';

export interface IAuthState {
  submitPhoneNumber: (phoneNumber: string) => Promise<void>;
  verifyPhoneNumber: (otp: string) => void;
  setDeviceID: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  error: any;
  phoneNumber: string;
  isAuthenticated: boolean;
  deviceID: string;
  firebase: typeof firebase;
}

export const AuthStateContext = React.createContext<IAuthState>({
  isAuthenticated: false,
  phoneNumber: '',
  error: false,
  loading: false,
  deviceID: '',
  submitPhoneNumber: () => Promise.resolve(),
  verifyPhoneNumber: () => Promise.resolve(),
  setDeviceID: () => {},
  firebase,
});

const AuthProvider = ({ children }) => {
  const state = useAuthProvider();

  return (
    <AuthStateContext.Provider value={state}>
      <div id="recaptcha-container" />
      {children}
    </AuthStateContext.Provider>
  );
};
export default AuthProvider;

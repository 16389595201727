import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import InfoCard from '../../../../common/_components/atoms/InfoCard';
import { useProcedureInfoModal } from '../../organisms/ProcedureInfo/hook';
import ProcedureInfo from '../../organisms/ProcedureInfo';
import { procedureSteps, videoTutorial, testInfoTextPerDevice, videoTutorialEmbedded } from '../../../_utils/constants';
import image from '../../../../../public/assets/undraw_medical_research_qg4d.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from '../../../../auth/Auth';

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      width: '100%',
      maxHeight: theme.spacing(25.5),
      [theme.breakpoints.up('md')]: {
        maxHeight: 243,
      },
      objectFit: 'fill',
    },
  }),
);

interface IHowToTest {}

const HowToTest: React.FC<IHowToTest> = ({ ...rest }) => {
  const { infoModalOpen, toggleInfoModal } = useProcedureInfoModal();
  const classes = useStyles();
  const {formatMessage} = useIntl();
  const { deviceID } = useAuth();
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <img
            src={image}
            className={classes.image}
            alt="Person standing with a file in hand related to medical research"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="textPrimary">
            {testInfoTextPerDevice[deviceID]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InfoCard
                text={formatMessage({ id: 'readInstructionsLabel' })}
                onClick={toggleInfoModal as any}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                text={formatMessage({ id: 'videoTutorialLabel' })}
                onClick={() => {
                  window.open(
                    videoTutorial[deviceID],
                    '_blank',
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ProcedureInfo
        isOpen={infoModalOpen}
        onClose={toggleInfoModal}
        title={formatMessage({ id: 'procedureStepsLabel' })}
        content={procedureSteps[deviceID]}
        iframeUrl={videoTutorialEmbedded[deviceID]}
      />
    </>
  );
};

export default HowToTest;

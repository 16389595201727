export const replacer = (key, value) => {
  if (value instanceof RegExp) return '__REGEXP ' + value.toString();
  else return value;
};

export const reviver = (key, value) => {
  if (value.toString().indexOf('__REGEXP ') == 0) {
    const m = value.split('__REGEXP ')[1].match(/\/(.*)\/(.*)?/);
    return new RegExp(m[1], m[2] || '');
  } else return value;
};

export const deepCopy = (json: any) => {
  return JSON.parse(JSON.stringify(json, replacer, 2), reviver);
};

export const formatDate = (date) => {
  let newDate = new Date(date);
  if (newDate.toString().includes('Invalid Date')) {
    const datePart = date.split('-');
    newDate = new Date(datePart[2], datePart[1] - 1, datePart[0]);
  }
  const newDateList = newDate.toString().split(' ');
  return newDateList[1] + ' ' + newDateList[2] + ', ' + newDateList[3];
};

export const getDisplayName = (name: string) => {
  const parts = name.split('_');
  let camelCaseString = '';
  parts.forEach((part) => {
    camelCaseString =
      camelCaseString +
      (part == 'OR' ? part.toLowerCase() : toProperCase(part)) +
      ' ';
  });
  return camelCaseString;
};

function toProperCase(s: string) {
  return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
}

export const getUserInitialFromName = (name: string | null | undefined) => {
  name = name || '';
  const names = name.trim().split(' ');
  return names.length !== 0
    ? names.length == 1
      ? (names[0].split('@')[0] || names[0]).substr(0, 2).toUpperCase()
      : (names[0] ? names[0].charAt(0).toUpperCase() : '') +
        (names[1] ? names[1].charAt(0).toUpperCase() : '')
    : '';
};

export const textClipper = (text: string, maxChar: number) => {
  if (text && text.length > maxChar) {
    text = text.substr(0, maxChar) + '...';
  }
  return text;
};

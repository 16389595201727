import React from 'react';
import { downloadData } from '../../../../services';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import history from '../../../../core-utils/history';
import { STORAGE } from '../../../../core-utils/routes';
import useBack from '../../../_hooks/useBack/index';

export const getDownloadFormat = () => {
  const [disableDownloadButton, setDownloadButton] = React.useState(true);
  const [selected, setSelected] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [patients, setPatients] = React.useState<any>([]);
  const [error, setError] = React.useState(false);
  const [keys, setKeys] = React.useState<any>([]);
  const [patient, setPatient] = React.useState<any>();
  const { location } = useHistory<any>();
  const filterFields = ['skyflow_id', 'unique_identifier'];
  const sheetHeaders = {
    name: 'Name',
    consent_given: 'Consent given',
    county: 'Country',
    device_identifier: 'Device Identifier',
    'first_test.value': 'First test',
    ordered: 'Ordered',
    'ordering_provider.name': 'Ordering provider name',
    pregnant: 'Pregnant',
    report_date: 'Report date',
    'result.date': 'Result date',
    'result.value': 'Result value',
    specimen_source: 'Specimen source',
    state: 'test State',
    'symptomatic.value': 'Symptomatic value',
    test_created_time: 'Test created time',
    test_image: 'Test image',
    test_updated_time: 'Test updated time',
    zip_code: 'test zip code',
    'address.city': 'City',
    'address.county': 'Country',
    'address.state': 'State',
    'address.street_address': 'Street address',
    'address.zip_code': 'Zip Code',
    age: 'Age',
    'consent.given': 'Consent',
    'consent.timestamp': 'Consent timestamp',
    created_timestamp: 'Created',
    date_of_birth: 'Dob',
    email_address: 'Emailaddress',
    'employed_in_healthcare.value': 'Employment',
    ethnicity: 'Ethnicity',

    phone_number: 'Phone number',
    race: 'Race',
    'residence_in_congregate_care.value': 'Residence in congregate care',
    sex: 'Gender',
    skyflow_id: 'Skyflow Id',
    unique_identifier: 'uid',
    updated_timestamp: 'Updated timestamp',
  };

  useBack(() => {
    history.push(STORAGE, { patient: patient });
  });

  React.useEffect(() => {
    const patientData = patient || location.state?.patient;
    if (patientData) {
      setLoading(true);
      downloadData(patientData.skyflowId)
        .then((res) => {
          setLoading(false);
          setError(false);
          if (res.data.rows) {
            const formatData = res.data.rows;
            setPatients(formatData);
            const keys =
              formatData[0] && getPaths(formatData[0]).map((x) => x.join('.'));
            setKeys(keys);
          }
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (!location.state) {
      history.push(STORAGE);
    } else {
      setPatient(location.state.patient);
    }
  }, [location.state]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
    setDownloadButton(false);
  };

  const downloadCSV = () => {
    const data = patients.map((x) =>
      getObject(x, requiredFields(keys, filterFields)),
    );
    const headers = sheetHeaders;
    if (filterFields.length > 0) {
      filterFields.forEach((field) => delete headers[field]);
    }

    exportCSVFile(headers, data, 'patientsData');
  };

  const downloadExcel = async () => {
    const data = patients.map((x) =>
      getExcelObject(x, requiredFields(keys, filterFields)),
    );

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data1 = new Blob([excelBuffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(data1, 'patientDataSheet' + '.xlsx');
  };

  const getPaths = (object) => {
    return (
      object &&
      typeof object === 'object' &&
      Object.keys(object).reduce(
        (p, k) =>
          (getPaths(object[k]) || [[]]).reduce((r, a) => [...r, [k, ...a]], p),
        [],
      )
    );
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  };

  const exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
      items.unshift(headers);
    }
    const jsonObject = JSON.stringify(items);

    const csv = convertToCSV(jsonObject);

    const exportedFilename = fileTitle;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, exportedFilename + '.csv');
  };

  const getObject = (obj, keys) => {
    const object = {};
    for (let i = 0; i < keys.length; i++) {
      const value = _.get(obj, keys[i]);
      if (!object.hasOwnProperty('name')) object['name'] = _.get(obj, 'name');
      if (!object.hasOwnProperty(keys[i]))
        object[keys[i]] = value?.toString().replace(/,/g, '') || '';
    }
    return object;
  };

  const getExcelObject = (obj, keys) => {
    const object = {};
    for (let i = 0; i < keys.length; i++) {
      const value = _.get(obj, keys[i]);
      if (!object.hasOwnProperty('Name')) object['Name'] = _.get(obj, 'Name');
      if (!object.hasOwnProperty(keys[i]))
        object[sheetHeaders[keys[i]]] =
          value?.toString().replace(/,/g, '') || '';
    }
    return object;
  };

  const requiredFields = (headers, fields) => {
    return headers.filter((x) => !fields.includes(x));
  };

  return {
    disableDownloadButton,
    handleChange,
    downloadCSV,
    downloadExcel,
    selected,
    loading,
    error,
  };
};
